import { MDText } from 'i18n-react';

import { vehicleType } from 'Utilities/helpers';

import step1Png from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-1.jpg';
import step1Webp from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-1.webp';
import step2Png from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-2.jpg';
import step2Webp from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-2.webp';
import step3Png from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-3.jpg';
import step3Webp from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-3.webp';
import step4Png from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-4.jpg';
import step4Webp from '../../../../client/assets_seller/valuations/whatsnext/whatsnext-step-4.webp';
import Texts from '../text/howTo.json';

const T = new MDText(Texts);

const stepsTitle = T.translate('title');

const stepsConfig = (brand = null, path = '') => {
  const vehicleText = vehicleType(path);
  const isVanPath = vehicleText === 'van';

  return [
    {
      imgPng: step1Png,
      imgWebp: step1Webp,
      text: isVanPath ? T.translate('step1.detailVan') : T.translate('step1.detail'),
      time: T.translate('step1.title', { vehicle: brand || vehicleText }),
    },
    {
      imgPng: step2Png,
      imgWebp: step2Webp,
      text: T.translate('step2.detail', {
        vanSuffix: isVanPath ? T.translate('vanSuffix') : '',
        vehicle: brand || vehicleText,
      }),
      time: T.translate('step2.title'),
    },
    {
      imgPng: step3Png,
      imgWebp: step3Webp,
      text: T.translate('step3.detail', { vehicle: vehicleText }),
      time: T.translate('step3.title'),
    },
    {
      imgPng: step4Png,
      imgWebp: step4Webp,
      text: T.translate('step4.detail', { vehicle: brand || vehicleText }),
      time: T.translate('step4.title'),
    },
  ];
};

export { stepsConfig, stepsTitle };
