// eslint-disable-next-line import/no-extraneous-dependencies
import dayjs from 'dayjs';

import { MDText } from 'i18n-react';

import LocalTexts from './formatter.texts.json';
import { clientSidePDR } from './helpers';

const LocalT = new MDText(LocalTexts);

// format the URL of an image to its imgix resized version
export const formatImgixUrl = (s3URL, opts = { h: 40, w: 40 }) => {
  if (!s3URL) {
    return null;
  }

  const h = opts.h || 40;
  const w = opts.w || 40;

  let imgixParamsCrop = '';
  const imgixParamsFit = (opts.fit) ? `&fit=${opts.fit}` : '&fit=crop';

  if (opts.meta && Number.isInteger(parseInt(opts.meta.x))) {
    // eslint-disable-next-line max-len
    imgixParamsCrop = `&rect=${opts.meta.x},${opts.meta.y},${opts.meta.width},${opts.meta.height}&or=${opts.meta.rotate}`;
  }

  const uri = new URL(s3URL);
  const imgixUrlEnv = `${uri.protocol}//${uri.host}`;
  const key = decodeURIComponent(uri.pathname || '');
  return `${imgixUrlEnv}${key}?w=${w}&h=${h}&dpr=${clientSidePDR()}${imgixParamsFit}${imgixParamsCrop}`;
};

export const formatNumber = (v) => (parseInt(v, 10) || 'N/A').toLocaleString('en-GB');

export const formatFloat = (v) => {
  const num = parseFloat(v);
  return Number.isNaN(num) ? 'N/A' : num.toLocaleString('en-GB', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

export const toPrice = (v) => {
  const value = formatFloat(v);

  if (value === 'N/A') {
    return '-';
  }

  return `£${value}`;
};

export const stringNumberToInt = (str) => parseInt(String(str || '')?.replace(/[^0-9]/g, ''));

export const toSpaced = (value) => value?.join?.(' ') || value;

export const boolToText = (v, mergedField) => {
  const { fallback: showFalseValue = true } = mergedField || {};

  if (v === true) {
    return LocalT.translate('yes');
  }

  if (v === false && showFalseValue) {
    return LocalT.translate('no');
  }

  if (!!v === false && !showFalseValue) {
    return false;
  }

  return '–';
};

export const toTitleCase = (s) =>
  (typeof s === 'string'
    ? s.substring(0, 1).toUpperCase() + s.substring(1).toLowerCase()
    : '');

export const formatDate = (date, format = 'DD/MM/YYYY') => {
  const dayjsDate = dayjs(date || null);
  return dayjsDate.isValid() ? dayjsDate.format(format) : undefined;
};

export const stringBooleanToBoolean = (string = '') => string?.toLowerCase?.() === 'true';

// source: https://community.shopify.com/c/Shopify-Design/Ordinal-Number-in-javascript-1st-2nd-3rd-4th/m-p/72156
export const getOrdinal = (n) => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const formatBrandNamePlural = (brand) => (brand.endsWith('s') ? brand : `${brand}s`);

export const replaceWhitespace = (text) => text.replace(/\s/g, '');

export const camelize = (str) => str
  .toLowerCase()
  .split('_')
  .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
  .join('');
