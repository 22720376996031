import React from 'react';
import PropTypes from 'prop-types';

import useTrustpilot from '../trustpilotCommon/useTrustpilot';

import TrustpilotCarouselBespoke from './TrustpilotCarouselBespoke';
import TrustpilotRatingBespoke from './TrustpilotRatingBespoke';

import styles from './TrustpilotBespoke.scss';

const TrustpilotBespoke = ({ brand = null }) => {
  const cardTruncationLength = 78;
  const cardTitleTruncationLength = 27;

  const {
    loaded, title, tpData, tpReviews: reviews,
  } = useTrustpilot({ brand, maxLength: cardTruncationLength });

  return (
    <section className={styles.component}>
      <div className={styles.container}>
        <h2>{title}</h2>
        <TrustpilotCarouselBespoke {...{
          brand,
          cardTitleTruncationLength,
          cardTruncationLength,
          loaded,
          reviews,
        }}
        />
        {tpData?.rating > 0 && <TrustpilotRatingBespoke {...{ tpData }} />}
      </div>
    </section>
  );
};

TrustpilotBespoke.propTypes = {
  brand: PropTypes.shape({
    display_name: PropTypes.string,
    slug: PropTypes.string,
  }),
};

export default TrustpilotBespoke;
