import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { InputIcon } from '@motorway/motorway-storybook-cra';
import { Badge } from '@motorway/mw-highway-code';
import { BADGE_VARIANT } from '@motorway/mw-highway-code/enums';

import cypressIds from 'CypressId';

import { GA_TAGS } from 'Utilities/analytics';
import { SP_HEADER_EVENTS } from 'Utilities/analytics/analyticsHeader';
import { applyCypressData } from 'Utilities/index';
import {
  CAREERS_URL,
  CONTACT_URL,
  HELP_URL,
  PRO_URL,
} from 'Utilities/urls';
import { MOT_VALUATION_ROUTE, TAX_VALUATION_ROUTE, ULEZ_PAGE_ROUTES, VALUATION_TRACKER_ROUTES } from 'Utilities/wordpress';

import { useBreakpoint } from '../../../components/misc/Breakpoints/Breakpoints';
import { routes } from '../../../routes';

import { HeaderLinksMobile } from './HeaderLinksMobile';
import LocalTexts from './HeaderTexts.json';

import styles from './Header.scss';

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

type SubRouteType = {
  badge?: string;
  caption?: string;
  icon?: string;
  isNewRoute?: boolean;
  label: string;
  path: string;
  trackingPath?: string;
};

type SubRoutesArray = SubRouteType[];

type RouteType = {
  label: string;
  name?: string;
  nav?: boolean;
  path: string;
  subRoutes?: SubRoutesArray;
  trackingPath?: string;
};

export const HeaderLinks = () => {
  const [activeGroup, setActiveGroup] = useState<string | null>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const breakpoints = useBreakpoint();
  const isMobile = breakpoints.maxWidth.breakpointTablet;

  const { pathname } = useLocation();

  const navRoutes: Pick<RouteType, 'label' | 'name' | 'nav' | 'path' | 'subRoutes'>[] = (routes as RouteType[]).filter((route) => route.nav);

  if (pathname !== '/') {
    navRoutes.unshift({
      label: t('navigation.sellMyCar'),
      nav: true,
      path: '/',
    });
  }

  const isExternalLink = (route:string) => /^https?:\/\//.test(route);
  const formatTrackingName = (name:string) => name.replace(/ /g, '_').toLowerCase();

  const newTab = 'new_tab';

  // eslint-disable-next-line consistent-return
  const fireTrackingEventForLink = (path:string, name:string) => {
    switch (path) {
    case CAREERS_URL:
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ label: newTab, name: 'careers', url: CAREERS_URL });
      break;

    case CONTACT_URL:
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ label: newTab, name: 'contact_us', url: CONTACT_URL });
      break;

    case HELP_URL:
      GA_TAGS.HEADER_HELP_LINK_CLICKED();
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ label: newTab, name: 'help', url: HELP_URL });
      break;

    case PRO_URL:
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ label: newTab, name: 'mw_for_dealers', url: PRO_URL });
      break;

    case VALUATION_TRACKER_ROUTES[0]:
      GA_TAGS.NEW_NAV_TRACKER_LINK_CLICKED();
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: 'cvt', url: VALUATION_TRACKER_ROUTES[0] });
      break;

    case ULEZ_PAGE_ROUTES[0]:
      GA_TAGS.NEW_NAV_ULEZ_LINK_CLICKED();
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: 'ulez', url: ULEZ_PAGE_ROUTES[0] });
      break;

    case MOT_VALUATION_ROUTE:
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: 'mot', url: MOT_VALUATION_ROUTE });
      break;

    case TAX_VALUATION_ROUTE:
      SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: 'tax_check', url: TAX_VALUATION_ROUTE });
      break;

    default: return SP_HEADER_EVENTS.HEADER_LINK_CLICKED({ name: formatTrackingName(name), url: path });
    }
  };

  const fireMenuHoverTracking = (message:string, name: string = '') => SP_HEADER_EVENTS.HEADER_LINK_HOVERED({ message: `${message} menu`, name });

  const controlLinkBehaviour = ({ label, path, trackingPath }:RouteType) => {
    if (isExternalLink(path)) {
      return (
        <a
          href={path}
          onClick={() => fireTrackingEventForLink(trackingPath || path, label)}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t(label)}
        </a>);
    }
    return (
      <Link
        onClick={() => fireTrackingEventForLink(trackingPath || path, label)}
        to={path}
      >
        {t(label)}
      </Link>);
  };

  const renderRoute = ({ label, name, path, trackingPath }:RouteType) => (
    <li
      key={`navlink-${path}-${label}`}
      className={cx(styles.navItem, styles.topLevel)}
      data-name={name}
    >
      { controlLinkBehaviour({ label, path, trackingPath }) }
    </li>
  );

  const renderSubRouteContent = ({ badge, caption, icon, label }: SubRouteType) => (
    <>
      { icon && <div className={cx(styles.icon, styles[icon])} /> }
      <div>
        <h6 className={cx(styles.label, { [styles.withBadge]: badge ?? false })}>
          { t(label) }
          {badge && <Badge text={t(badge)} variant={ BADGE_VARIANT.PRIMARY } /> }
        </h6>
        { caption && <span className={styles.caption}>{t(caption)}</span> }
      </div>
    </>
  );

  const renderSubRoutes = (subRoutes:SubRoutesArray) =>
    subRoutes.map(({
      badge,
      caption,
      icon,
      label,
      path,
      trackingPath,
    }: SubRouteType) => (
      <li
        key={`navlink-sub-${label}`}
        className={cx(styles.navItem, {
          [styles.withCaption]: caption,
        })}
      >
        {isExternalLink(path) ? (
          <a
            href={path}
            onClick={() => fireTrackingEventForLink(trackingPath || path, label)}
            rel="noopener noreferrer"
            target="_blank"
          >
            { renderSubRouteContent({ badge, caption, icon, label, path }) }
          </a>
        ) : (
          <Link
            onClick={() => fireTrackingEventForLink(trackingPath || path, label)}
            to={path}
          >
            { renderSubRouteContent({ badge, caption, icon, label, path }) }
          </Link>
        )}
      </li>
    ));

  return (
    <>
      <a
        className={styles.mobileMoreMenu}
        {...applyCypressData(cypressIds.menus.mobileHamburgerMenu)}
        href="#menu"
        onClick={(e) => {
          e.preventDefault();
          setMenuOpen(!menuOpen);
        }}
      >
        {t('navigation.openMenuLabel')}
        <span />
      </a>

      <nav className={cx(styles.nav, styles.links, styles.navDesktop)}>
        <ul {...applyCypressData(cypressIds.menus.desktopMenu)}>
          {navRoutes.map(({ label, name, path, subRoutes }) => {
            if (subRoutes) {
              return (
                <li
                  key={`navlink-${name}-${label}`}
                  className={styles.navItem}
                  data-name={name}
                >
                  <div
                    className={cx(styles.toggle, {
                      [styles.active]: activeGroup === label,
                    })}
                  >
                    <div
                      className={styles.groupToggle}
                      onMouseEnter={() => fireMenuHoverTracking(label, name)}
                    >
                      <span>{t(`${label}`)}</span>
                      <InputIcon className="mw-input-icon" icon="select" />
                    </div>
                    <div className={cx(styles.subMenu)}>
                      <ul>{renderSubRoutes(subRoutes)}</ul>
                    </div>
                  </div>
                </li>
              );
            }
            return renderRoute({ label, name, path });
          })}
        </ul>
      </nav>
      {
        isMobile && (
          <HeaderLinksMobile
            {...{
              menuOpen,
              setMenuOpen,
            }}
          >
            <ul {...applyCypressData(cypressIds.menus.mobileMenu)}>
              {navRoutes.map(({ label, name, path, subRoutes }) => {
                if (subRoutes) {
                  const active = (activeGroup === label);
                  return (
                    <li
                      key={`navlink-${name}-${label}`}
                      className={styles.navItem}
                      data-cy={name}
                      data-name={name}
                    >
                      <div
                        className={cx(styles.toggle, {
                          [styles.active]: active,
                        })}
                      >
                        <button
                          aria-controls={name}
                          aria-expanded={active}
                          className={styles.groupToggle}
                          onClick={() => setActiveGroup(activeGroup === label ? null : label)}
                          onMouseEnter={() => fireMenuHoverTracking(label, name)}
                          type='button'
                        >
                          { t(`${label}`) }
                          <InputIcon icon="select" />
                        </button>
                        <div className={cx(styles.subMenu)} id={name}>
                          <ul>{renderSubRoutes(subRoutes)}</ul>
                        </div>
                      </div>
                    </li>
                  );
                }
                return renderRoute({ label, name, path });
              })}
            </ul>
          </HeaderLinksMobile>
        )
      }
    </>
  );
};
