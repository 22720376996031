import React from 'react';

import { usePrimaryButtonExperiment } from './usePrimaryButtonExperiment';

export const PrimaryButtonExperiment: React.FC = () => {
  const theme = usePrimaryButtonExperiment();
  if (!theme) {
    return null;
  }

  return (
    <style dangerouslySetInnerHTML={{ __html: `:root body [class*="Button-module__primary"] {--button-background-color: ${theme};}` }} />
  );
};
