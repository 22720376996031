import React, { useContext, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { ConfigContext } from 'Context/config';

import { vehicleType } from 'Utilities/helpers';
import useInView from 'Utilities/useInView';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';
import useBrandName from '../hooks/useBrandName';

import LocalTexts from './VideoSectionTexts.json';

import styles from './VideoSection.scss';

const LocalT = new MDText(LocalTexts);

const VideoSection = ({ onLoad = () => {} }) => {
  const { configState: { youtubeEmbedURL } } = useContext(ConfigContext); // eslint-disable-line no-unused-vars
  const brandName = useBrandName();

  const $container = useRef();
  const visible = useInView($container, {
    disconnectOnVisible: true,
    rootMargin: '200px',
  });

  const breakpoints = useBreakpoint();
  const { breakpointTablet } = breakpoints.maxWidth;

  const { pathname } = useLocation();
  const vehicleTypeString = vehicleType(pathname);

  const strap = (<p><span data-nosnippet>{LocalT.translate('strap', { vehicle: vehicleTypeString })}</span></p>);

  const renderVehicleCopy = () => {
    if (brandName) {
      return brandName;
    }
    return vehicleTypeString;
  };

  useEffect(() => {
    if (visible) {
      onLoad?.();
    }
  }, [visible]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={$container}
      className={ styles.component }
      data-testid='videoSection'
    >
      <Content element="section">
        <div className={styles.container}>
          <div data-nosnippet className={styles.text}>
            <h2>
              {LocalT.translate('title', { vehicle: renderVehicleCopy() })}
            </h2>
            {!breakpointTablet && strap}
          </div>
          <div className={styles.videoContainer}>
            <div className={styles.video}>
              {
                (visible) ? (
                  <iframe
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    frameBorder="0"
                    height="310"
                    loading="lazy"
                    src={youtubeEmbedURL}
                    title={LocalT.translate('videoTitle')}
                    width="512"
                  />
                ) : (null)
              }
            </div>
            {breakpointTablet && strap}
          </div>
        </div>
      </Content>
    </div>
  );
};

VideoSection.propTypes = {
  onLoad: PropTypes.func,
};

export default VideoSection;
