import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ChevronLeftThinCircleIcon as PrevNextComponent, LoadingSpinner } from '@motorway/motorway-storybook-cra';

import {
  REVIEW_BODY_MAX_LENGTH,
  REVIEW_TITLE_MAX_LENGTH,
} from 'Utilities/helpers';

import TrustpilotCommonCarousel from '../trustpilotCommon/TrustpilotCommonCarousel';

import TrustpilotCardComponent from './TrustpilotCardBespoke';

import styles from './TrustpilotCarouselBespoke.scss';

const TrustpilotCarouselBespoke = ({
  brand = {
    display_name: null,
    slug: null,
  },
  cardTitleTruncationLength = REVIEW_TITLE_MAX_LENGTH,
  cardTruncationLength = REVIEW_BODY_MAX_LENGTH,
  loaded = false,
  reviews,
}) => {
  const generateBreakPointConfig = useCallback((breakpoints) => {
    const slidesNum = [
      {
        breakpoint: 'breakpointMobile',
        slides: 1,
      },
      {
        breakpoint: 'breakpointTabletMobile',
        slides: 2,
      },
      {
        breakpoint: 'breakpointTablet',
        slides: 3,
      },
    ].find(({ breakpoint }) => breakpoints[breakpoint])?.slides || 4;

    return {
      allowTouchMove: breakpoints.breakpointTablet,
      slidesPerGroup: slidesNum,
      slidesPerView: slidesNum,
      spaceBetween: 24,
      speed: 1000,
    };
  }, []);

  if (!loaded) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <TrustpilotCommonCarousel
      {...{
        brand,
        cardTitleTruncationLength,
        cardTruncationLength,
        generateBreakPointConfig,
        loaded,
        minReviews: 1,
        PrevNextComponent,
        reviews,
        styles,
        TrustpilotCardComponent,
      }}
    />
  );
};

TrustpilotCarouselBespoke.propTypes = {
  brand: PropTypes.shape({
    display_name: PropTypes.string,
    slug: PropTypes.string,
  }),
  cardTitleTruncationLength: PropTypes.number,
  cardTruncationLength: PropTypes.number,
  loaded: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default TrustpilotCarouselBespoke;
