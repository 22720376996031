import React, { ReactNode } from 'react';
import cx from 'classnames';

import Tippy, { TippyProps } from '@tippy.js/react';

import styles from './Tooltip.module.scss';

const MAX_WIDTH_FALLBACK = 288;

export const DEFAULT_TOOLTIP_Z_INDEX = 2500;

const DEFAULT_DISTANCE = 16;

export const TooltipThemes = {
  DARK: 'dark',
  LIGHT: 'light',
  WARNING: 'warning',
} as const;

const tooltipPlacementMap = {
  bottom: 'bottom',
  center: 'top',
  left: 'left',
  right: 'right',
  top: 'top',
  topLeft: 'top-start',
  topRight: 'top-end',
} as unknown as Record<string, TippyProps['placement']>;

const whitelistPlacement = (placement: keyof typeof tooltipPlacementMap) => (
  placement !== undefined ? tooltipPlacementMap[placement] : tooltipPlacementMap.top
);

const renderChildren = (children: ReactNode): React.ReactElement => {
  // Use React.Children.only to ensure a single child is passed
  const child = React.Children.only(children) as unknown as React.ReactElement;

  // for components you don't have access to set ref, wrap in <span>
  // @ref https://github.com/atomiks/tippy.js-react/issues/49#issuecomment-470198792
  if (React.isValidElement(child) && typeof child.type === 'function') {
    return <span>{child}</span>;
  }

  return child;
};

/**
 * Tooltip component renders tooltip with content.
 *
 */

export type TooltipProps = {
  appendTo?: TippyProps['appendTo'];
  arrow?: TippyProps['arrow'];
  bordered?: boolean;
  boundary?: TippyProps['boundary'];
  children?: ReactNode;
  className?: string;
  flipBehavior?: TippyProps['flipBehavior'];
  flipOnUpdate?: boolean;
  interactive?: boolean;
  maxWidth?: TippyProps['maxWidth'];
  overlay: TippyProps['content'];
  placement?: keyof typeof tooltipPlacementMap;
  tooltipTheme?: string;
  visible?: boolean;
  zIndex?: number;
}

export const Tooltip = ({
  appendTo,
  arrow = true,
  bordered = false,
  boundary = 'window',
  children = null,
  className,
  flipBehavior = 'flip',
  flipOnUpdate = false,
  interactive = false,
  maxWidth = MAX_WIDTH_FALLBACK,
  overlay,
  placement = tooltipPlacementMap.top,
  tooltipTheme = TooltipThemes.DARK,
  visible,
  zIndex = DEFAULT_TOOLTIP_Z_INDEX,
}: TooltipProps) => {
  // "appendTo" defaults to "parent" instead of "document.body" if "interactive: true"
  // need to explicitly set "document.body"
  if (!appendTo && typeof window !== 'undefined') {
    appendTo = document.body;
  }

  return (
    <Tippy
      flip
      animation="fade"
      appendTo={appendTo}
      arrow={arrow}
      boundary={boundary}
      className={cx(styles.tooltip, styles[`tooltip--${tooltipTheme}-mode`], {
        [styles['tooltip--bordered']]: bordered,
        [styles['tooltip--default']]: !bordered,
      }, className)}
      content={overlay}
      distance={DEFAULT_DISTANCE}
      flipBehavior={flipBehavior}
      flipOnUpdate={flipOnUpdate}
      hideOnClick={false}
      interactive={interactive}
      maxWidth={maxWidth}
      placement={whitelistPlacement(placement!)}
      theme={tooltipTheme}
      visible={visible}
      zIndex={zIndex}
    >
      {renderChildren(children)}
    </Tippy>
  );
};
