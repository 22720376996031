import { useContext, useEffect, useRef } from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';

import { ConfigContext } from 'Context/config';

import { GA_TAGS, SNOWPLOW_TRIGGER } from 'Utilities/analytics';
import metatitle from 'Utilities/metatitle';
import routing from 'Utilities/routing';
import T from 'Utilities/texts';

import { routes } from '../../../routes';

const MetaTitle = ({ history }) => {
  const {
    configActions: { updateZendesk },
    configState: { brandData },
  } = useContext(ConfigContext);

  const routeRef = useRef(routing.getMatch(history?.location?.pathname, routes));
  const pathnameRef = useRef(history?.location?.pathname);

  useEffect(() => {
    const toggleZendesk = (show = false) => updateZendesk({ show });

    const updateMeta = (pathname) => {
      routeRef.current = routing.getMatch(pathname, routes);
      const { current: route } = routeRef;

      toggleZendesk(route.zendeskWidget);

      // We need data from the api to correctly display the title so hold fire till the revelant `useEffect` can kick in
      if (route?.refreshMetaManually) {
        return;
      }

      if (route?.meta) {
        const { meta: { description, title } } = route;

        if (title) {
          metatitle.formatTitle(pathname, title);
        }

        if (description) {
          metatitle.formatDescription(description || T.translate('homeView.metaTitle'));
        }
      }
    };

    toggleZendesk(routeRef.current.zendeskWidget);

    const unlisten = history.listen(({ pathname }) => {
      updateMeta(pathname);
    });

    return () => unlisten();
  }, [history, updateZendesk]);

  useEffect(() => {
    const unlisten = history.listen(({ pathname }) => {
      if (pathname !== pathnameRef.current) {
        GA_TAGS.PAGE_TRANSITION_COMPLETE();
        SNOWPLOW_TRIGGER('trackPageView');
      }

      pathnameRef.current = pathname;
    });

    return () => unlisten();
  }, [history]);

  useEffect(() => {
    const { current: route } = routeRef;

    const displayName = brandData?.brand?.display_name;
    const slug = brandData?.brand?.slug;

    if ((route?.params?.brand === slug) && displayName) {
      metatitle.formatTitle(route.url, T.translate('brandView.metaTitle', { brand: displayName }));
      metatitle.formatDescription(T.translate('brandView.metaDescription', { brand: displayName }));
    }
  }, [brandData?.brand?.slug, brandData?.brand?.display_name]);

  return (null);
};

MetaTitle.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(MetaTitle);
