import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button, WarningIcon } from '@motorway/mw-highway-code';

import { VRM_CHECK_CODES } from 'Utilities/vrm';

import LocalTexts from './HomeVRMErrorsTexts.json';

import styles from './HomeVRMErrors.scss';

const LocalT = new MDText(LocalTexts);

type HomeVRMErrorsProps = {
  error: Record<string, string> & {
    error: string;
    vrm: string;
  } | null;
};

const HomeVRMErrors = ({
  error = null,
}: HomeVRMErrorsProps) => {
  if (!error) {
    return (null);
  }

  const { error: errorMsg, vrm } = error;

  let title;
  let detail;
  let additional;

  const refreshButton = (
    <div className={styles.button}>
      <Button
        fullWidth
        as={'button'}
        label={LocalT.translate('refresh') }
        onClick={(e) => {
          e.preventDefault();
          window.location.reload();
        }}
      />
    </div>
  );

  switch (errorMsg) {
  case VRM_CHECK_CODES.EXCEEDED: {
    title = LocalT.translate('exceeded.title');
    detail = LocalT.translate('exceeded.detail');
    additional = (
      <div className={styles.button}>
        <Button
          data-thc-button
          fullWidth
          as={Link}
          icon="chevron"
          label={LocalT.translate('goToAccount')}
          size='large'
          to="/account"
        />
      </div>
    );

    break;
  }
  case VRM_CHECK_CODES.INVALID_VRM: {
    title = LocalT.translate('invalid_vrm.title', { vrm });
    detail = LocalT.translate('invalid_vrm.detail');

    break;
  }
  case VRM_CHECK_CODES.TOO_MANY_REQUESTS: {
    title = LocalT.translate('too_many_requests.title');
    detail = LocalT.translate('too_many_requests.detail');

    break;
  }
  case VRM_CHECK_CODES.VEHICLE_GENERATION_FAILURE: {
    title = LocalT.translate('vehicle_generation_failure.title');
    detail = LocalT.translate('vehicle_generation_failure.detail');

    additional = refreshButton;

    break;
  }
  case VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED: {
    title = LocalT.translate('vehicle_lookup_failed.title');
    detail = LocalT.translate('vehicle_lookup_failed.detail');

    break;
  }
  default: {
    title = LocalT.translate('default.title');
    detail = LocalT.translate('default.detail');

    additional = refreshButton;

    break;
  }
  }

  return (
    <div className={cx(styles.component, styles[`error_${errorMsg}`])}>
      <WarningIcon data-thc-icon />
      <h2>{ title }</h2>
      <p>{ detail }</p>
      { additional }
    </div>
  );
};

export default HomeVRMErrors;
