import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { VehicleLookupContext } from 'Context/vehicleLookup';
import { VehicleContext } from 'Context/vehicles';

import { VALUATIONS_GA_TAGS } from 'Utilities/analytics';
import { HOMEPAGE_GA_TAGS } from 'Utilities/analytics/analyticsHomePage';
import { EVENT } from 'Utilities/analytics/optimizely/constants';
import { useOptimizelyTrack } from 'Utilities/analytics/optimizely/useOptimizelyTrack';
import { HERO_ANIMATION_STATES } from 'Utilities/animation';
import { coerceVRM, INTENT, isValidVRM, stripSpaces, unprettyVRM } from 'Utilities/formValidators';
import { useFeatureFlags } from 'Utilities/hooks';
import { findVehicleByVrm } from 'Utilities/vehicles/findBy';
import useFindExistingEnquiry from 'Utilities/vehicles/hooks/useFindVehicleEnquiry';
import { VRM_CHECK_CODES } from 'Utilities/vrm';

import { useVrmCheckAction } from 'Valuations/Hooks/useVrmCheckAction';

import { captureVrmError } from '../vrmInput/VrmInputHelpers';

const checkVRM = (vrmValue: string) => (isValidVRM(vrmValue) ? coerceVRM(vrmValue) : '');

type useVrmInputProps = {
  isHomepage:boolean;
  loadingHandler: (value: string) => void;
  onError?: (...args: unknown[]) => void;
  onSuccess?: (value: string) => void;
  setShowMileage: (value: boolean) => void;
  valuationHub?: boolean;
  vrm: string;
}

const useHeroVrmInput = ({
  isHomepage,
  loadingHandler,
  onError,
  onSuccess,
  setShowMileage,
  valuationHub = false,
  vrm,
}: useVrmInputProps) => {
  const { isRefreshLookupVehicleAfterFetch, isSkippingCreateEnquiryForSellingStates } = useFeatureFlags();
  const history = useHistory();
  const location = useLocation();

  const [vrmValue, setVrmValue] = useState<string>(checkVRM(vrm));
  const { vehicleLookupActions } = useContext(VehicleLookupContext);
  const { vehicleState: vehicles } = useContext(VehicleContext);
  const { getDealershipEnquiry, getRedirectValueForSellingState, isEnquiryInSellingState } = useFindExistingEnquiry();

  const { vrmCheckAction } = useVrmCheckAction();

  useEffect(() => {
    const searchParams = Object.fromEntries(new URLSearchParams(location.search));
    const searchVrm = searchParams?.vrm || '';

    if (isValidVRM(searchVrm)) {
      setVrmValue(coerceVRM(searchVrm));
    } else {
      setVrmValue(checkVRM(vrm));
    }

    return () => setVrmValue(checkVRM(vrm));
  }, [vrm, location]);

  const [errors, setErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [wrongVRM, setWrongVRM] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);
  const previousErrors = useRef(errors);
  const { OPTIMIZELY_TRACK } = useOptimizelyTrack();

  useEffect(() => {
    if (errors.length && !previousErrors.current.length) {
      const analyticsVector = (isHomepage) ? HOMEPAGE_GA_TAGS : VALUATIONS_GA_TAGS;
      if (!valuationHub) {
        analyticsVector.HERO_INVALID_VRM_WARNING_SHOWN(vrmValue);
      }
    }
    previousErrors.current = errors;
  }, [errors, isHomepage, valuationHub, vrmValue]);

  const checkErrors = useCallback(() => {
    const isInvalid = !isValidVRM(vrmValue);

    if (isInvalid) {
      setErrors(['Invalid VRM']);
    }
    return isInvalid;
  }, [vrmValue]);

  const showIntent = useCallback(() => {
    if (errors.length || wrongVRM) {
      return INTENT.ERROR;
    }

    if (isValidVRM(vrmValue)) {
      return INTENT.SUCCESS;
    }

    return null;
  }, [errors.length, wrongVRM, vrmValue]);

  // this is to have the input still show intent without the tooltip when there is an API error
  const showIntentInput = useCallback(() => {
    if (hasApiError && isHomepage) {
      return null;
    }

    return showIntent();
  }, [hasApiError, isHomepage, showIntent]);

  const getErrorMsg = useCallback(() => {
    const msg = (errors?.[0] === 'vehicle_lookup_failed')
      ? 'notRegistered'
      : 'errorMessage';

    return msg;
  }, [errors]);

  const checkMileageSkipStep = useCallback(async () => {
    // We only need the mileage step for creating an enquiry,
    // so if an enquiry is in selling state then skip the mileage input
    const currentVehicle = findVehicleByVrm(vehicles, unprettyVRM(vrmValue));
    const dpEnquiry = await getDealershipEnquiry(currentVehicle);
    const isEnquiryInSellingStatus = currentVehicle && isEnquiryInSellingState(currentVehicle, dpEnquiry);
    if (!valuationHub && dpEnquiry && isEnquiryInSellingStatus && isSkippingCreateEnquiryForSellingStates) {
      vehicleLookupActions.add({ enquiry: currentVehicle?.enquiry, vehicle: currentVehicle });
      const redirectTo = getRedirectValueForSellingState(currentVehicle, dpEnquiry.state.slug);
      VALUATIONS_GA_TAGS.HERO_MILEAGE_SKIPPED();
      if (redirectTo) {
        history.push(redirectTo);
        return true;
      }
    }
    return false;
  }, [
    getDealershipEnquiry,
    getRedirectValueForSellingState,
    history,
    isEnquiryInSellingState,
    isSkippingCreateEnquiryForSellingStates,
    valuationHub,
    vehicleLookupActions,
    vehicles,
    vrmValue,
  ]);

  const onFormSubmit = useCallback(async () => {
    if (checkErrors()) {
      return;
    }

    setLoading(true);
    loadingHandler(HERO_ANIMATION_STATES.vrm);

    if (!valuationHub) {
      VALUATIONS_GA_TAGS.HERO_VRM_BUTTON_CLICKED_SNOWPLOW(unprettyVRM(vrmValue));
    }
    if (isHomepage) {
      HOMEPAGE_GA_TAGS.HERO_VRM_CTA_CLICKED();
    } else {
      VALUATIONS_GA_TAGS.HERO_VRM_BUTTON_CLICKED();
    }

    if (await checkMileageSkipStep()) {
      return;
    }

    const {
      error: apiError,
    }: {
      error?: string;
    } = await vrmCheckAction({
      isRefreshLookupVehicleAfterFetch,
      isValuationHub: valuationHub,
      vrmValue: unprettyVRM(vrmValue),
    });

    loadingHandler(HERO_ANIMATION_STATES.none);
    if (apiError) {
      captureVrmError(new Error(apiError), unprettyVRM(vrmValue));

      if (([VRM_CHECK_CODES.VEHICLE_LOOKUP_FAILED, VRM_CHECK_CODES.INVALID_VRM, VRM_CHECK_CODES.BAD_REQUEST]
        .includes(apiError as any))) {
        setHasApiError(true);
        setLoading(false);
        setWrongVRM(true);
      }

      if ([VRM_CHECK_CODES.EXCEEDED].includes(apiError as any)) {
        setLoading(false);
        setHasApiError(true);
      }

      onError?.(unprettyVRM(vrmValue), apiError, setWrongVRM);
      return;
    }

    if (!valuationHub) {
      VALUATIONS_GA_TAGS.HERO_VEHICLE_LOOKUP_FORM_SUCCESS(unprettyVRM(vrmValue));
      OPTIMIZELY_TRACK(EVENT.HERO_VRM_CTA_CLICKED);
    }

    setHasApiError(false);
    setLoading(false);
    setShowMileage(true);
    onSuccess?.(unprettyVRM(vrmValue));
  }, [
    checkErrors,
    checkMileageSkipStep,
    isHomepage,
    isRefreshLookupVehicleAfterFetch,
    loadingHandler,
    onError,
    onSuccess,
    setShowMileage,
    valuationHub,
    vrmCheckAction,
    vrmValue,
  ]);

  const onInputBlur = useCallback(() => {
    if (!vrmValue || checkErrors()) {
      return;
    }
    setVrmValue(coerceVRM(vrmValue));
  }, [checkErrors, vrmValue]);

  const onInputChange = useCallback((value: string) => {
    // Don't prettify VRM onChange because a VRM like `HJ1 ABC`...
    // ...can be coerced into `HJI ABC` when `HJ1 A` is being typed in
    setVrmValue(stripSpaces(value));
    setErrors([]);
  }, []);

  const onInputFocus = useCallback(() => {
    setErrors([]);

    if (isHomepage) {
      HOMEPAGE_GA_TAGS.HERO_VRM_INPUT_FOCUSSED();
    } else {
      VALUATIONS_GA_TAGS.HERO_VRM_INPUT_FOCUSED();
    }
  }, []);

  return {
    getErrorMsg,
    hasApiError,
    loading,
    onFormSubmit,
    onInputBlur,
    onInputChange,
    onInputFocus,
    showIntent,
    showIntentInput,
    vrmValue,
    wrongVRM,
  };
};

export default useHeroVrmInput;
