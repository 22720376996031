import { FC, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

import { PortalProps } from './types';

export const Portal: FC<PortalProps> = ({ children, containerClass, id }) => {
  const elRef = useRef<HTMLDivElement | null>(null);
  const rootRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    elRef.current = document.createElement('div');
    if (containerClass && elRef.current) {
      elRef.current.setAttribute('class', containerClass);
    }

    if (id) {
      rootRef.current = document.getElementById(id);
    }

    if (!rootRef.current) {
      rootRef.current = document.createElement('div');
      if (id) {
        rootRef.current.setAttribute('id', id);
      }
      document.body.appendChild(rootRef.current);
    }

    if (rootRef.current && elRef.current) {
      rootRef.current.appendChild(elRef.current);
    }

    return () => {
      if (rootRef.current && elRef.current) {
        rootRef.current.removeChild(elRef.current);
      }
    };
  }, [containerClass, id]);

  if (!rootRef.current) {
    return null;
  }

  return ReactDOM.createPortal(children, elRef.current!);
};
