import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { GA_TAGS } from 'Utilities/analytics';
import { classNamePropType } from 'Utilities/propTypes';

import { GENERIC_ANALYTICS_EVENTS } from '../../utilities/analytics/analyticsGenericEvents';
import { useBreakpoint } from '../misc/Breakpoints/Breakpoints';
import SvgAnimationBox from '../svgAnimationBox/SvgAnimationBox';

import styles from './Slider.scss';

const loadSwiper = () => Promise.all([
  import(/* webpackChunkName: "swiper", webpackPreload: true */ 'swiper'),
  import(/* webpackChunkName: "swiper/modules", webpackPreload: true */ 'swiper/modules'),
])
  .then(([{ default: Swiper }, { A11y, Pagination }]) => {
    Swiper.use([Pagination, A11y]);
    return Swiper;
  })
  .catch((err) => {
    window?.Sentry?.captureException?.(new Error(err), {
      level: 'warning',
      tags: {
        fetch: 'swiper',
      },
    });
  });

const getProgress = (itemProgress, currentProgress, itemLength) => {
  let timelineProgress = 0;

  if (
    itemProgress - currentProgress >= 0
    && itemProgress - currentProgress <= itemLength
  ) {
    timelineProgress = Math.round(
      (1 - (itemProgress - currentProgress) / itemLength) * 100,
    );
  } else if (currentProgress >= itemProgress) {
    timelineProgress = 100;
  }

  return timelineProgress;
};

const getOpacity = (itemProgress, currentProgress, itemLength) => {
  let opacity = 0.2;

  const diff = itemProgress - currentProgress;

  if (Math.abs(diff) < itemLength) {
    opacity = 1 - 0.8 * (Math.abs(diff) / itemLength);
  }
  if (itemProgress === currentProgress) {
    opacity = 1;
  }

  return opacity;
};

const Slider = ({
  analyticsConfig, className, config, showImages = false, startAnimation = false, title = '', withAnimation = false,
}) => {
  const [isVisibleSwiper, setIsVisibleSwiper] = useState(false);

  const [currentProgress, setCurrentProgress] = useState(0);

  const [timelineData, setTimeLineData] = useState([]);

  const [slideWidth, setSlideWidth] = useState(0);

  const [timelineOffset, setTimelineOffset] = useState(0);

  const [timelineTranslate, setTimelineTranslate] = useState(0);

  const [inTransition, setInTransition] = useState(false);

  const el = useRef();
  const pagination = useRef();

  const timelineItems = useRef([]);

  const breakpoints = useBreakpoint();
  const mobileBreakpoint = breakpoints.maxWidth.breakpointMobile;
  const tabletBreakpoint = breakpoints.maxWidth.breakpointTablet;

  const swiperInstance = useRef(null);

  useEffect(() => {
    let observer;

    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver((entries) => {
        const { isIntersecting } = entries[0];

        if (isIntersecting) {
          setIsVisibleSwiper(true);
          observer?.disconnect();
        }
      }, {
        rootMargin: `${window.innerHeight * 1.5}px 0px`,
        threshold: [1],
      });
      observer.observe(el.current);
    } else {
      setIsVisibleSwiper(true);
    }

    return () => observer?.disconnect();
  }, []);

  useEffect(() => {
    if (!isVisibleSwiper) {
      return () => {};
    }

    const initSwiper = async () => {
      const Swiper = await loadSwiper();
      if (Swiper) {
        swiperInstance.current = new Swiper(el.current, {
          centeredSlides: true,
          direction: 'horizontal',
          loop: false,
          // adds swiper-slide-visible class to visible slides
          on: {
            init(swiper) {
              setSlideWidth(swiper.slidesSizesGrid[0]);
              setTimelineOffset(-swiper.slidesGrid[0]);
            },
            progress: (_, progress) => {
              let roundedProgress = parseFloat(progress.toFixed(3));
              if (roundedProgress < 0) {
                roundedProgress = 0;
              } else if (roundedProgress > 1) {
                roundedProgress = 1;
              }
              setCurrentProgress(roundedProgress);
            },
            resize(swiper) {
              setSlideWidth(swiper.slidesSizesGrid[0]);
              setTimelineOffset(-swiper.slidesGrid[0]);
            },
            slideNextTransitionStart: (swiper) => {
              if (title) {
                GA_TAGS.CAROUSEL_CLICKED(title, 'Next');
              }
              if (analyticsConfig) {
                GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
                  ...analyticsConfig,
                  label: `Step ${swiper.activeIndex + 1}`,
                });
              }
            },
            slidePrevTransitionStart: (swiper) => {
              if (title) {
                GA_TAGS.CAROUSEL_CLICKED(title, 'Previous');
              }

              if (analyticsConfig) {
                GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
                  ...analyticsConfig,
                  label: `Step ${swiper.activeIndex + 1}`,
                });
              }
            },
            transitionEnd: () => {
              setInTransition(false);
            },
            transitionStart: () => {
              setInTransition(true);
            },
          },

          pagination: {
            dynamicBullets: true,
            el: pagination.current,
          },

          slidesPerView: mobileBreakpoint ? 1.2 : 1.5,

          spaceBetween: mobileBreakpoint ? 24 : 16,
          watchSlidesVisibility: true,
        });
      }
    };

    const swiper = swiperInstance.current;

    if (!swiper || swiper.destroyed) {
      initSwiper();
    }

    // clean up
    return () => {
      if (!tabletBreakpoint) {
        if (swiper && !swiper.destroyed) {
          swiper.destroy();
        }
      }
    };
  }, [isVisibleSwiper, tabletBreakpoint]);

  useEffect(() => {
    const swiper = swiperInstance.current;
    if (swiper && !swiper.destroyed) {
      swiper.params.slidesPerView = mobileBreakpoint ? 1.2 : 1.5;
      swiper.update();
    }
  }, [mobileBreakpoint]);

  useEffect(() => {
    const itemLength = parseFloat((1 / (config.length - 1)).toFixed(3));

    const newTimelineData = config.map((item, index) => {
      const { time } = item;

      const itemProgress = index * itemLength;

      const active = itemProgress === currentProgress;

      const timelineProgress = getProgress(
        itemProgress,
        currentProgress,
        itemLength,
      );

      const opacity = getOpacity(itemProgress, currentProgress, itemLength);

      return {
        active, itemProgress, opacity, time, timelineProgress,
      };
    });

    setTimeLineData(newTimelineData);
  }, [config, currentProgress]);

  useEffect(() => {
    const itemLength = parseFloat((1 / (config.length - 1)).toFixed(3));

    const widths = timelineItems.current.map((element) => element.offsetWidth);

    const currentItem = Math.floor(currentProgress / itemLength);

    const offset = widths.reduce((acc, item, index) => {
      if (index < currentItem) {
        acc += item;
      }

      if (index === currentItem) {
        acc += item * (currentProgress / itemLength - currentItem);
      }

      return acc;
    }, 0);

    setTimelineTranslate(-offset + slideWidth / 2 - widths[currentItem] / 2);
  }, [config.length, currentProgress, timelineItems, slideWidth, mobileBreakpoint]);

  return (
    <div className={cx(styles.component, className)}>
      <div className={styles.timeline}>
        <div
          className={styles.timelineWrapper}
          style={{
            transform: `translate3d(${timelineOffset
              + timelineTranslate}px, 0, 0)`,
            transition: `all ${inTransition ? 300 : 0}ms ease 0s`,
          }}
        >
          {timelineData.map(({ opacity, time, timelineProgress }, index) => (
            <div
              key={time}
              ref={(item) => {
                timelineItems.current[index] = item;
              }}
              className={styles.timelineItem}
            >
              {index !== 0 && (
              <div
                className={cx(styles.line, {
                  [styles.complete]: timelineProgress === 100,
                })}
              >
                <div
                  style={{
                    transition: `all ${inTransition ? 300 : 0}ms ease 0s`,
                    width: `${timelineProgress}%`,
                  }}
                />
              </div>
              )}
              <span
                className={cx(styles.time, {
                  [styles.active]: timelineProgress > 0,
                })}
                style={{ opacity }}
              >
                {time}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div ref={el} className={cx('swiper', styles.swiperContainer)}>
        <div className="swiper-wrapper">
          {config.map((item, i) => (
            <div key={item.time} className="swiper-slide">
              {withAnimation ? (
                <SvgAnimationBox
                  animate={((swiperInstance?.current?.realIndex || 0) === i && startAnimation)}
                  hiddenClassName={styles.svgHidden}
                  id={item.id}
                  showSvg={showImages}
                  svg={item.svg}
                />
              ) : (
                <picture>
                  <source srcSet={item.imgWebp} type="image/webp" />
                  <img
                    alt={item.time}
                    className={styles.image}
                    height={item.height}
                    loading="lazy"
                    src={item.imgPng}
                    width={item.width}
                  />
                </picture>
              )}
              <p>{item.text}</p>
            </div>
          ))}
        </div>

        <div ref={pagination} className="swiper-pagination" />
      </div>
    </div>
  );
};

Slider.propTypes = {
  analyticsConfig: PropTypes.objectOf(PropTypes.string),
  className: classNamePropType,
  config: PropTypes.arrayOf(PropTypes.shape).isRequired,
  showImages: PropTypes.bool,
  startAnimation: PropTypes.bool,
  title: PropTypes.string,
  withAnimation: PropTypes.bool,
};

export default Slider;
