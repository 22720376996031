import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import _merge from 'lodash-es/merge';

import { SWIPER_PAGINATION } from './helpers';

const loadSwiper = (type) => Promise.all([
  import(/* webpackChunkName: "swiper", webpackPreload: true */ 'swiper'),
  import(/* webpackChunkName: "swiper/modules", webpackPreload: true */ 'swiper/modules'),
])
  .then(([{ default: Swiper }, { A11y, Navigation, Pagination }]) => {
    const modules = type === SWIPER_PAGINATION ? [A11y, Pagination] : [A11y, Navigation];
    Swiper.use(modules);
    return Swiper;
  })
  .catch((err) => {
    window?.Sentry?.captureException?.(new Error(err), {
      level: 'warning',
      tags: {
        fetch: 'swiper',
      },
    });
  });

const swiperCommonConfig = {
  direction: 'horizontal',
  loop: false,
  preventInteractionOnTransition: true,
  watchSlidesVisibility: true,
};

const useIsomorphicLayoutEffect = (callback, deps) => (typeof window === 'undefined' ? useEffect : useLayoutEffect)(callback, deps);

const useSwiperCarousel = ({ config: swiperInstanceConfig, enableSwiper }) => {
  const [, setSwiperLoaded] = useState(false); // Trigger re-render to pass swiper instance into consumer

  const swiperInstance = useRef();
  const { $el, type: swiperType, ...config } = swiperInstanceConfig;

  useIsomorphicLayoutEffect(() => {
    if (!enableSwiper) {
      return () => {};
    }

    const { current: swiper } = swiperInstance;

    const initSwiper = async () => {
      const Swiper = await loadSwiper(swiperType);
      const swiperConfig = _merge(swiperCommonConfig, config);

      if (Swiper) {
        swiperInstance.current = new Swiper($el, swiperConfig);
        setSwiperLoaded(true);
      }
    };

    if ($el && !swiper) {
      initSwiper();
    }

    return () => swiper?.destroy?.();
  }, [$el, enableSwiper, swiperType]);

  return swiperInstance.current;
};

export default useSwiperCarousel;
