import React from 'react';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { InfoBanner } from '@motorway/motorway-storybook-cra';

import { ConfigStateStatic } from 'Context/config';

import LocalTexts from './AnnouncementTexts.json';

import styles from './Announcement.scss';

const LocalT = new MDText(LocalTexts);

export const TYPES = {
  BUSY_DAYS: 'busyDays',
  COVID: 'covid',
  ULEZ_SERVICE_ERROR: 'ulez',
};

// To turn off the banner just set `ANNOUNCEMENT_ID` to null
export const ANNOUNCEMENT_ID = null;
export const ANNOUNCEMENT_COOKIE_PREFIX = 'infoBanner-';
export const ANNOUNCEMENT_COOKIE = `${ANNOUNCEMENT_COOKIE_PREFIX}${ANNOUNCEMENT_ID}`;

const Announcement = ({ type = TYPES.COVID }) => {
  const { state: { hideAnnouncement } } = ConfigStateStatic;

  if (hideAnnouncement || !ANNOUNCEMENT_ID) {
    return (null);
  }

  const renderCopy = () => {
    let info;

    if (type === TYPES.COVID) {
      info = {
        description: 'covid.desc',
        title: 'covid.title',
      };
    }

    if (type === TYPES.BUSY_DAYS) {
      info = {
        description: 'busyDays.desc',
        title: 'busyDays.title',
      };
    }

    if (type === TYPES.ULEZ_SERVICE_ERROR) {
      info = {
        description: 'ulez.desc',
        title: 'ulez.title',
      };
    }

    return (
      <>
        {info.title && (
          <span>
            {LocalT.translate(`${info.title}`)}
          </span>
        )}

        <span className={styles.info}>
          {LocalT.translate(`${info.description}`)}
        </span>
      </>
    );
  };

  return (
    <div className={styles.component}>
      <InfoBanner
        ssrVisible
        icon={null}
        id={ANNOUNCEMENT_ID}
        title={renderCopy()}
        titleClassName={styles.title}
      />
    </div>
  );
};

Announcement.propTypes = {
  type: PropTypes.oneOf([TYPES.COVID, TYPES.BUSY_DAYS, TYPES.ULEZ_SERVICE_ERROR]),
};

export default Announcement;
