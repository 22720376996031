import { useCallback, useContext } from 'react';

import { OptimizelyContext } from '@optimizely/react-sdk';

import { UserContext } from 'Context/user';

import { optimizelyAttributes } from './optimizely.helper';

export const useOptimizelyTrack = () => {
  const { userState: user } = useContext(UserContext);
  const { optimizely } = useContext(OptimizelyContext);

  const optimizelyTrack = useCallback((
    event: string,
    attributes = {},
    properties: Record<string, string | number | undefined> | null = null,
    forceDpSellerId = false,
  ) => {
    setTimeout(
      () => optimizely?.track(
        event,
        forceDpSellerId && user?.dpSellerId ? user.dpSellerId.toString() : undefined,
        optimizelyAttributes({ ...attributes, user: { id: user?.id } }),
        // @ts-ignore - this is a valid property definition
        { $opt_event_properties: properties },
      ),
      0,
    );
  }, [optimizely, user?.id, user?.dpSellerId]);

  return { OPTIMIZELY_TRACK: optimizelyTrack };
};
