/* eslint-disable camelcase */
import { createInstance, enums } from '@optimizely/react-sdk';

import { captureExceptionWithTeam } from 'Utilities/captureException/captureExceptionWithTeam';
import { TEAM } from 'Utilities/constants';

import { snowplowEvent } from '../analytics';

type OptimizelyDecisionEventProps = {
  decision: string;
  flag_enabled: boolean;
  flag_name: string;
  optimizely_user_id: string;
  rule_name: string;
}

type OptimizelyTrackEventProps = {
  datafile_version: string;
  event_name: string;
  optimizely_user_id: string;
  sdk_name: string;
}

const OPTIMIZELY_EVENT = {
  DECISION: (data: OptimizelyDecisionEventProps) => snowplowEvent({
    data,
    schema: 'optimizely_decision',
  }),
  TRACK: (data: OptimizelyTrackEventProps) => snowplowEvent({
    data,
    schema: 'optimizely_event',
  }),
};

/// ////////////////////////////////////////
// NOTIFICATION LISTENERS - For tracking Optimizely decisions and events actions //
/// ////////////////////////////////////////

export type OnDecisionProps = {
  attributes: any;
  decisionInfo: {
    decisionEventDispatched: boolean;
    enabled: boolean;
    flagKey: string;
    reasons: string[];
    ruleKey: string;
    variables: object;
    variationKey: string;
  };
  type: string;
  userId: string;
}

export const onDecision = ({ decisionInfo, type, userId }: OnDecisionProps) => {
  if (type === 'flag') {
    OPTIMIZELY_EVENT.DECISION({
      decision: decisionInfo.variationKey,
      flag_enabled: decisionInfo.enabled,
      flag_name: decisionInfo.flagKey,
      optimizely_user_id: userId,
      rule_name: decisionInfo.ruleKey,
    });
  }
};

export type OnTrackProps = {
  attributes: any;
  eventKey: string;
  eventTags?: any;
  logEvent: {
    httpVerb: string;
    params: {
      account_id: string;
      client_name: string;
      client_version: string;
      enrich_decisions: boolean;
      project_id: string;
      revision: string;
      visitors: any;
    };
    url: string;
  };
  userId: string;
}

export const onTrack = ({ eventKey, logEvent, userId }: OnTrackProps) => {
  OPTIMIZELY_EVENT.TRACK({
    datafile_version: logEvent.params.revision,
    event_name: eventKey,
    optimizely_user_id: userId,
    sdk_name: logEvent.params.client_name,
  });
};

export const logToSentry = (level: number, message: string) => {
  const { ERROR, WARNING } = enums.LOG_LEVEL;

  if ([ERROR, WARNING].includes(level)) {
    captureExceptionWithTeam(new Error(`[Optimizely] - ${message}`), TEAM.TURBO_CHARGE_FE, { level: 'info' });
  }
};

export const optimizelyInstanceFactory = (key: string) => createInstance({
  eventBatchSize: 10,
  eventFlushInterval: 1000,
  logger: {
    log: logToSentry,
  },
  logLevel: enums.LOG_LEVEL.DEBUG,
  odpOptions: {
    disabled: true,
  },
  sdkKey: key,
});

export const formatExperimentDecisionsHeader = (experimentDecisions: string) => {
  const obj: Record<string, string> = {};
  if (!experimentDecisions) {
    return obj;
  }
  decodeURIComponent(experimentDecisions)?.split(',').forEach((experiment) => {
    const [key, value] = experiment.split('=');
    obj[key] = value;
  });
  return obj;
};
