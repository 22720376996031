import React, { KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import cx from 'classnames';

import { isString } from '@motorway/motorway-tools/typeHelpers';
import { CrossIcon } from '@motorway/mw-highway-code';

import { Dialog } from './Dialog';
import { Portal } from './Portal';
import { ModalProps } from './types';

import styles from './Modal.module.scss';

export const getClassNameFromEvent = (e: MouseEvent<HTMLDivElement>) => {
  const target = e?.target as HTMLElement;
  const className = target?.className;
  // check for string value as `document` will return undefined and SVGs return an object
  return isString(className) ? className : '';
};

const closeButtonClassNames = [styles.dismiss, 'modal-close'];

const dialogCloseAnimationDuration = Number(styles.dialogCloseAnimationDuration) || 0;

const dismissDetailsDefault = {
  isCloseButtonClick: false,
  isEscKeyPress: false,
  isOverlayClick: false,
};

export const Modal = ({
  centered = true,
  children = null,
  className,
  closeIcon = <CrossIcon />,
  closeIconClass,
  contentClass,
  dialogClass,
  dismissModal: onClose = () => {},
  modalProps = {},
  noPadding = false,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  size = 'large',
  withCloseButton = true,
  withPanelStyles = true,
}: ModalProps) => {
  const [isActive, setIsActive] = useState(false);

  const modalClass = cx(
    styles.component,
    className,
    {
      [styles.active]: isActive,
      [styles.noPadding]: noPadding,
      [styles.centered]: centered,
    },
    styles[`${size}Size`],
  );

  const closeModal = useCallback((dismissDetails = {}) => {
    const details = { ...dismissDetailsDefault, ...dismissDetails };
    setIsActive(false);
    setTimeout(() => onClose(details), dialogCloseAnimationDuration);
  }, [onClose]);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    const targetClassName = getClassNameFromEvent(e);
    const isCloseButtonClick = closeButtonClassNames.some((classname) => targetClassName.includes(classname));
    const isOverlayClick = targetClassName.includes(styles.component);
    const shouldNotClose = !isCloseButtonClick && (!shouldCloseOnOverlayClick || !isOverlayClick);

    if (shouldNotClose) {
      return;
    }

    closeModal({ isCloseButtonClick, isOverlayClick });
  };

  const closeModalOnEsc = useCallback((e: Event) => {
    if (!shouldCloseOnEsc || (e as unknown as KeyboardEvent).key !== 'Escape') {
      return;
    }

    closeModal({ isEscKeyPress: true });
  }, [closeModal, shouldCloseOnEsc]);

  const handleBackdropClick = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      handleClick(e as unknown as MouseEvent<HTMLDivElement>);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', closeModalOnEsc);
    setIsActive(true);

    return () => {
      document.removeEventListener('keydown', closeModalOnEsc);
    };
  }, [closeModalOnEsc, shouldCloseOnEsc]);

  return (
    <Portal id="modal-root">
      <RemoveScroll>
        <div
          aria-label="modal-backdrop"
          className={modalClass}
          onClick={handleClick}
          onKeyDown={handleBackdropClick}
          {...modalProps}
        >
          <Dialog className={cx(styles.dialog, dialogClass)} panel={withPanelStyles}>
            {withCloseButton && (
              <div aria-label="close" className={cx(styles.dismiss, closeIconClass)}>
                {closeIcon}
              </div>
            )}
            <div className={cx(styles.content, contentClass)}>
              {children}
            </div>
          </Dialog>
        </div>
      </RemoveScroll>
    </Portal>
  );
};
