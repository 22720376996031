import React from 'react';
import PropTypes from 'prop-types';

import { MDText } from 'i18n-react';

import { preparedDisplayNames } from 'Utilities/helpers';

import HighlightWord from './HighlightWord';
import LocalTexts from './TrustpilotTexts.json';

import styles from './TrustpilotModal.scss';

const LocalT = new MDText(LocalTexts);

const TrustpilotModal = ({ body, title, vehicleMake = null }) => (
  <div className={styles.component}>
    <div className={styles.starRating}>{LocalT.translate('rating')}</div>
    <h3>
      <HighlightWord words={preparedDisplayNames(vehicleMake)}>{title}</HighlightWord>
    </h3>
    <p><HighlightWord words={preparedDisplayNames(vehicleMake)}>{body}</HighlightWord></p>
  </div>
);

export default TrustpilotModal;

TrustpilotModal.propTypes = {
  body: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  vehicleMake: PropTypes.string,
};
