import { useContext, useEffect } from 'react';

import { UserContext } from 'Context/user';

import { initBroadcast, postBroadcast } from 'Utilities/broadcastChannel';
import { PATH_UNSUBSCRIBE } from 'Utilities/routing';
import useScrubQueryParam from 'Utilities/useScrubQueryParam';

export const useBroadcast = () => {
  const { userState: user } = useContext(UserContext);

  const scrubbedToken = useScrubQueryParam(
    globalThis?.location?.pathname?.startsWith(`/${PATH_UNSUBSCRIBE.split('/')[1]}`)
      ? null
      : ['token', 'authorization_code'],
  );

  useEffect(() => {
    initBroadcast();
  }, []);

  useEffect(() => {
    if (Number.isInteger(user?.id) && scrubbedToken) {
      postBroadcast(); // Sign in any open background tabs
    }
  }, [scrubbedToken, user?.id]);
};
