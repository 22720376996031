import React, { useEffect, useRef, useState } from 'react';
import cx, { Argument as ClassNameType } from 'classnames';
import _debounce from 'lodash-es/debounce';
import _throttle from 'lodash-es/throttle';

import { useStopAnimationFallback } from 'Utilities/animation';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';

import styles from './ChevronAnimation.scss';

type ChevronAnimationProps = {
  className?: ClassNameType;
  isTransition?: boolean;
  isUpwardsVariant?:boolean;
  loading?: boolean;
  setAnimationInProgress?: (inProgress: boolean) => void;
};

const STOP_TIME_OUT = 3000;
const minWidth = 1440;
const maxWidth = parseInt(styles.maxWidth);
const step = minWidth / 100;

const ChevronAnimation = ({
  className,
  isTransition = false,
  isUpwardsVariant = false,
  loading = false,
  setAnimationInProgress = () => {},
}: ChevronAnimationProps) => {
  const breakpoints = useBreakpoint();
  const chevron = useRef<HTMLDivElement>(null);
  const { breakpointContent } = breakpoints.minWidth;
  const [resizing, setResizing] = useState<boolean>(false);
  const [shift, setShift] = useState<number | string>('0');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const chevronElem = chevron && chevron.current;
    const stopProgress = () => setAnimationInProgress(false);

    if (chevronElem) {
      chevronElem.addEventListener('animationend', stopProgress);
      chevronElem.addEventListener('animationcancel', stopProgress);

      return () => {
        chevronElem.removeEventListener('animationend', stopProgress);
        chevronElem.removeEventListener('animationcancel', stopProgress);
      };
    }
  }, [chevron, setAnimationInProgress]);

  useStopAnimationFallback(loading, () => setAnimationInProgress(false), STOP_TIME_OUT);

  useEffect(() => {
    if (!breakpointContent) {
      return;
    }

    const clearResizing = _debounce(() => setResizing(false), 500);
    const resizeHandler = _throttle(() => {
      setResizing(true);
      clearResizing();
    }, 100);
    window.addEventListener('resize', resizeHandler, { passive: true } as EventListenerOptions);

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('resize', resizeHandler, { passive: true } as EventListenerOptions);
    };
  }, [breakpointContent]);

  useEffect(() => {
    const width = window.innerWidth > maxWidth ? maxWidth : window.innerWidth;
    const timeShift = Math.ceil((width - minWidth) / step) / 100;
    setShift(timeShift);
  }, [resizing]);

  return (
    <div className={cx(styles.animationBox, className)} style={{ '--animation-shift': `${shift}s` } as React.CSSProperties}>
      <div
        ref={chevron}
        className={cx(styles.chevron, {
          [styles.start]: loading,
          [styles.transition]: isTransition,
        }, { [styles.upwardsVariant]: isUpwardsVariant })}
      />
    </div>
  );
};

export default ChevronAnimation;
