import React, { useContext } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { ConfigContext } from 'Context/config';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';

import LocalTexts from './BrandingContentTexts.json';

import styles from './BrandingContent.scss';

const LocalT = new MDText(LocalTexts);

const defaultContent = {
  content: Object.keys(LocalTexts.default.content),
  type: 'default',
};

const BrandingContent = () => {
  const { configState: { brandData } } = useContext(ConfigContext);
  const { main } = brandData?.brandContent || {};
  const breakpoints = useBreakpoint();

  const renderContent = () => {
    if (main) {
      return <div dangerouslySetInnerHTML={{ __html: main }} className={styles.main} />;
    }

    const { content, type } = defaultContent;

    return (
      <>
        <h3>{ LocalT.translate(`${type}.title`, { br: breakpoints?.maxWidth?.breakpointMobile ? <br /> : '' }) }</h3>
        {content.map((key) => (
          <p key={key}>
            {LocalT.translate(`${type}.content.${key}`)}
          </p>
        ))}
      </>
    );
  };

  return (
    <Content
      className={cx(styles.component, { [styles.manufacturer]: main })}
      element="section"
    >
      <div className={cx(styles.content)}>
        {renderContent()}
      </div>
    </Content>
  );
};

export default BrandingContent;
