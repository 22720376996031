import React from 'react';
import cx from 'classnames';

import { HelpIcon } from '@motorway/mw-highway-code';

import styles from './TooltipAnchor.module.scss';

export type TooltipAnchorProps = {
  background?: boolean | null;
  className?: string;
};

export const TooltipAnchor = ({
  background = null,
  className,
  ...props
}: TooltipAnchorProps) => {
  const tooltipAnchorClassName = cx(
    className,
    styles.anchor,
    {
      [styles.background]: background,
    },
  );

  return (
    <span {...props} className={tooltipAnchorClassName}>
      <HelpIcon />
    </span>
  );
};
