import React, { useState } from 'react';

import { Content } from '@motorway/motorway-storybook-cra';

import TrustpilotReviewsCarousel from '../../../../../components/reviews/ReviewsCarousel/TrustpilotReviewsCarousel';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';

import styles from './FooterReviews.scss';

const FooterReviews = () => {
  const [failedReviewsCarousel, setFailedReviewsCarousel] = useState(false);

  return (
    <RoundedSection>
      <Content
        center
        className={styles.reviews}
        element="section"
        wrapper={{ className: styles.wrapper }}
      >
        <TrustpilotReviewsCarousel
          failedLoad={failedReviewsCarousel}
          setFailedLoad={setFailedReviewsCarousel}
        />
      </Content>
    </RoundedSection>
  );
};

export default FooterReviews;
