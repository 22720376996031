import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { DotList } from '@motorway/motorway-storybook-cra';
import { VRM } from '@motorway/mw-highway-code/alpha';

import cypressIds from 'CypressId';

import { applyCypressData } from 'Utilities/index';
import { vehiclePropType } from 'Utilities/propTypes';

import { useBreakpoint } from '../../../../components/misc/Breakpoints/Breakpoints';

import styles from './HomeVRM.scss';

const HomeVehicle = ({ modal = false, valuationHub = false, vehicle }) => {
  const breakpoints = useBreakpoint();
  const mobileBreakpoint = breakpoints.maxWidth.breakpointMobile;

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!vehicle) {
    return (null);
  }

  const vrmBadgeConfig = valuationHub ? {
    size: 'small',
  } : {
    size: (mobileBreakpoint) ? 'small' : 'large',
    white: true,
  };

  return (
    <div className={styles.component}>
      <div className={ cx({
        [styles.valuationHub]: valuationHub,
        [styles.center]: modal,
      }) }>
        <div
          className={cx(styles.vrmBadgeContainer, { [styles.show]: loaded })}
        >
          <VRM
            data-thc-vrm
            text={vehicle.vrm_pretty}
            { ...vrmBadgeConfig }
          />
        </div>
      </div>
      <div className={ cx({
        [styles.valuationHub]: valuationHub,
        [styles.center]: modal,
      }) }>
        <h1 className={cx(styles.vehicleTitle, { [styles.small]: modal })}
        {...applyCypressData(cypressIds.strings.vehicleMakeAndModel)}>
          {vehicle.make?.display_name}
          {' '}
          {vehicle.model}
        </h1>
        <DotList
          narrow
          className={cx(styles.vehicleDetails, { [styles.small]: modal })}
          {...applyCypressData(cypressIds.strings.vehicleSpecifics)}
          data={[
            vehicle.manufactureYear?.toString() || vehicle.year?.toString(),
            vehicle.colour,
            vehicle.body,
            vehicle.fuel,
          ]}
        />
      </div>
    </div>
  );
};

HomeVehicle.propTypes = {
  modal: PropTypes.bool,
  valuationHub: PropTypes.bool,
  vehicle: vehiclePropType.isRequired,
};

export default HomeVehicle;
