export const SNOWPLOW_TRIGGER = (value: string, arg: string) => {
  setTimeout(() => {
    if (arg) {
      return window?.snowplow?.(value, arg);
    }
    return window?.snowplow?.(value);
  }, 0);
};

// XXX: https://github.com/snowplow/snowplow-javascript-tracker/blob/8fa9bc58280860e5d59dedffc1bb130ca74af28c/trackers/javascript-tracker/src/in_queue.ts#L260
export const SNOWPLOW_GET_TRACKERS = (callback: () => void) => window?.snowplow?.(callback);

export const getSnowplowIds = () => {
  const snowplowNetworkUserId = document.cookie.split('; ').find((cookie) => cookie.startsWith('sp='))?.split('=')[1];

  let snowplowDomainUserId;
  let snowplowSessionId;
  SNOWPLOW_GET_TRACKERS(function getTrackers(this:any) {
    const firstTracker = Object.values(this)[0] as any;
    [, snowplowDomainUserId, , , , , snowplowSessionId] = firstTracker?.getDomainUserInfo() || [];
  });

  return { snowplowDomainUserId, snowplowNetworkUserId, snowplowSessionId };
};
