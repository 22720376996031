export const isMobileDevice = ({ includeTablet = true }) => {
  const isMobile = /(Android|iPhone|mobile)/i.test(window.navigator.userAgent);
  const isTablet = /(tablet|iPad)|(android(?!.*mobile))/i.test(window.navigator.userAgent);

  return includeTablet ? isMobile : (isMobile && (!includeTablet && !isTablet));
};

export const isIOS = () => /(iPhone|iPad)/.test(window.navigator.userAgent);
export const isIPhone = () => /(iPhone)/.test(window.navigator.userAgent);

// Desktop/Laptop and Desktop/Laptop + Touchscreen
export const isDesktopDevice = () => !window.matchMedia?.('(pointer:coarse)')?.matches;
