import React from 'react';
import cx from 'classnames';

import { DialogProps } from './types';

import styles from './Dialog.module.scss';

export const Dialog = ({
  children, className, panel = false, shaded = false,
}: DialogProps) => (
  <div
    aria-modal="true"
    className={cx(styles.component, className)}
    role="dialog"
  >
    <div
      className={cx(
        { [styles.panel]: panel },
        { [styles.shaded]: shaded },
      )}
    >
      {children}
    </div>
  </div>
);
