import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { ComponentsWrapper } from 'Layout';

import { VehicleHeader } from 'Storybook/components/local/VehicleHeader/VehicleHeader';

import PageLoader from '../../../../components/transitions/PageLoader/PageLoader';
import Loader from '../valuations/components/Loader';

import LocalTexts from './VehicleHeaderWrapperTexts.json';

import styles from './VehicleHeaderWrapper.scss';

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

type VehicleHeaderWrapperProps = {
  animate?: boolean;
  loading?: boolean;
};

type HistoryState = {
  animate?: boolean;
  animateFromNewHomePage?: boolean;
} | null;

const VehicleHeaderWrapper = ({
  animate = false,
  loading = false,
}: VehicleHeaderWrapperProps) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);

  const history = useHistory<HistoryState>();
  const { animate: transitView } = history?.location?.state ?? {};

  return (
    <>
      {
        (vehicle?.id) ? (
          <>
            <div
              className={cx(styles.vehicleHeader)}
            >
              <div className={styles.vehicleInner}>
                <Content className={styles.content}>
                  <VehicleHeader
                    link={{
                      label: t('changeVehicle'),
                      to: '/',
                    }}
                    {...{ vehicle }}
                  />
                </Content>
              </div>
            </div>
            {
              loading && (
                // @ts-ignore
                <ComponentsWrapper className={cx({ [styles.loaderHidden]: transitView && !animate })}>
                  <Loader />
                </ComponentsWrapper>
              )
            }
          </>
        ) : (
          <>
            { loading && <PageLoader /> }
          </>
        )
      }
    </>
  );
};

export default VehicleHeaderWrapper;
