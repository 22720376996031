import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './ThemeDark.scss';

const ThemeDark = (props) => {
  const {
    children = [], className, ...p
  } = props; // eslint-disable-line no-unused-vars

  return (
    <div className={cx('mw-parent-theme-dark', styles.theme, className)} {...p}>
      {children}
    </div>
  );
};

ThemeDark.propTypes = {
  children: PropTypes.shape(),
  className: PropTypes.func,
};

export default ThemeDark;
