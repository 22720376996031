import React from 'react';
import cx from 'classnames';

import styles from './RoundedSection.scss';

type RoundedSectionType = 'primary-mid-light-yellow' | 'primary-yellow' | 'off-white';

type RoundedSectionProps = {
  children?: React.ReactNode;
  className?: string;
  theme?: RoundedSectionType;
  withBottomLedge?: boolean;
  withTopLedge?: boolean;
};

export const RoundedSection: React.FC<RoundedSectionProps> = ({
  children,
  className,
  theme,
  withBottomLedge = false,
  withTopLedge = false,
}) => (
  <section className={cx(styles.section, className, {
    [styles.withBottomLedge]: withBottomLedge,
    [styles.withTopLedge]: withTopLedge,
  })} data-theme={theme}>{children}</section>
);
