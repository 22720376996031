import { useEffect } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';

const useScrubQueryParam = (keys: string[] = []) => {
  const history = useHistory();
  const queryParams = qs.parse(history.location.search);

  const keysToScrub = keys?.filter((key) => key in queryParams);

  useEffect(() => {
    if (keysToScrub?.length) {
      const otherParams = Object.entries(queryParams).reduce<Record<string, any>>((acc, [k, v]) => {
        if (keysToScrub.includes(k)) {
          return acc;
        }
        acc[k] = v;
        return acc;
      }, {});

      const newLocation = {
        ...history.location,
        search: qs.stringify(otherParams),
      };

      history.replace(newLocation);
    }
  }, [history, keysToScrub, queryParams]);

  return keysToScrub?.length;
};

export default useScrubQueryParam;
