/* eslint-disable max-classes-per-file, react/prop-types */
import React from 'react';

import { withUserContext } from '../context/user';
import { withVehicleContext } from '../context/vehicles';
import { init } from '../services/api';

const StoreContext = React.createContext();

class StoreProvider extends React.PureComponent {
  constructor(props) {
    super(props);

    const { initialState } = props;

    init({
      eligibilityGateway: initialState.eligibilityGateway,
      gateway: initialState.gateway,
      reviewsApi: initialState.reviewsApi,
      wordpressApi: initialState.wordpressApi,
    });

    this.update = this.update.bind(this);

    this.state = {
      ...initialState,
    };
  }

  update(data) {
    if (data) {
      this.setState(data);

      // The below check is necessary because the store is a PureComponent,
      // therefore the changes made in the user object aren't spotted
      if (data.user) {
        // eslint-disable-next-line react/no-unused-state
        this.setState({ cacheBust: Date.now() });
      }
    }
  }

  render() {
    const { children } = this.props;
    return (
      <StoreContext.Provider
        value={{
          state: this.state,
          update: this.update,
        }}
      >
        {children}
      </StoreContext.Provider>
    );
  }
}

const connect = (Component) => {
  class ConsumerComponent extends React.PureComponent {
    render() {
      const {
        forwardedRef, userContext, vehicleContext, ...rest
      } = this.props;
      const { userState: user } = userContext;
      const { vehicleState: vehicles } = vehicleContext;

      return (
        <StoreContext.Consumer>
          { (context) => {
            const hackedContext = { ...context, state: { ...context.state, user: { ...user, vehicles } } };
            const hackedProps = { ...rest, context: hackedContext };
            return (<Component ref={forwardedRef} {...hackedProps} />);
          }}
        </StoreContext.Consumer>
      );
    }
  }

  return (
    withVehicleContext(
      withUserContext(
        React.forwardRef((props, ref) => (
          <ConsumerComponent {...props} forwardedRef={ref} />
        )),
      ),
    )
  );
};

export { StoreProvider, StoreContext, connect };
