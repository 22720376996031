import React from 'react';
import { useHistory, useLocation } from 'react-router';
import cx from 'classnames';

import styles from './Header.scss';

export const HeaderBackground: React.FC = () => {
  const { pathname } = useLocation();
  const showTranslucentHeader = ['/', '/mileage'].includes(pathname);

  const history = useHistory<{ animateFromNewHomePage?: boolean }>();
  const { animateFromNewHomePage } = history?.location?.state ?? {};

  return (
    <div className={cx(styles.headerBackground, {
      [styles.isHomepage]: showTranslucentHeader,
      [styles.animated]: !!animateFromNewHomePage,
    })} />
  );
};
