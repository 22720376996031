import fetch from 'cross-fetch';
import qs from 'query-string';

import { logger } from 'logger';

import { ConfigStateStatic } from 'Context/config';
import { getCognitoAuthToken, UserStateStatic } from 'Context/user';

import { getSnowplowIds } from 'Utilities/analytics';
import { PROVIDERS_VERSIONS_KEY } from 'Utilities/constants';

const defaultHeaders = {
  'Cache-Control': 'no-cache',
  'x-mway-requester': 'motorway-seller-webapp',
};

export const ABORT_NAME = 'AbortError';
export const DEFAULT_ABORT_TIME = 5000;

export const LOGGING_OPTIONS = {
  LOG_PATHNAME: 'pathname',
  LOG_SEARCH: 'search',
};

export const LOGGING_LEVELS = {
  ERROR: 'error',
  WARN: 'warn',
};

export const timeoutFetch = (
  fetchUrl,
  {
    abortController = new AbortController(),
    loggerLevel = LOGGING_LEVELS.WARN,
    logging = [],
    ms = DEFAULT_ABORT_TIME,
    ...fetchOptions
  } = {},
) => {
  const { signal } = abortController;

  const timeout = setTimeout(() => abortController.abort(`Timed out in ${ms}ms. (${fetchUrl})`), ms);

  return new Promise((resolve, reject) => {
    const abortFetch = (res) => {
      clearTimeout(timeout);

      const { aborted, reason } = signal;

      if (aborted) {
        const { captureException } = globalThis?.Sentry || {};

        let connection = {};

        if (globalThis?.navigator?.connection) {
          const {
            downlink, effectiveType, rtt, saveData,
          } = navigator.connection;

          connection = {
            downlink,
            effectiveType,
            rtt,
            saveData,
          };
        }

        const url = new URL(
          fetchUrl,
          [globalThis?.location?.origin || process?.env?.HOST || 'https://motorway.co.uk'],
        );

        const extraFingerprints = logging.map((k) => url[k]);

        const errorTitle = reason;
        const errorDetails = {
          extra: {
            connection,
            reason,
            url: fetchUrl,
          },
          fingerprint: ['{{ default }}', url.host, ...extraFingerprints],
          tags: {
            fetch: 'timeout promise',
          },
        };

        // Don't log intentional aborts, debounce etc
        // Anything passed into `abort()` will be returned as is, array, string etc
        // If nothing is passed in it will be an instance of DOMException
        if (!(errorTitle instanceof DOMException)) {
          if (captureException) {
            captureException(new Error(errorTitle), errorDetails);
          } else if (logger?.[loggerLevel]) {
            logger[loggerLevel]('timeoutFetchError', errorTitle, errorDetails);
          } else {
            console.error(errorTitle, errorDetails); // eslint-disable-line no-console
          }
        }

        reject(res);
        return;
      }

      reject(res);
    };

    fetch(fetchUrl, { ...fetchOptions, signal })
      .then((res) => {
        clearTimeout(timeout);
        resolve(res);
      })
      .catch((res) => {
        abortFetch(res);
      });
  });
};

/**
 * The timeoutFetch function logs a custom error for AbortError.
 * This function should be used to prevent duplicate logging of AbortError from other sections of the codebase.
 */
export const timeoutFetchSentryGuard = ((err, cb) => {
  if (err?.name !== ABORT_NAME) {
    cb();
  }
});

// Urgh, if you destructure on initial import and if React hasn't constructed the context...
// ...then this will be the empty default.
// Since we only really call this from inside React Components if we destructure on call, we get what we need.
// TODO - Somehow make this consistent all the time

const getGatewayApi = () => {
  const { state: { gatewayApi } } = ConfigStateStatic;

  return gatewayApi;
};

const getEligibilityApi = () => {
  const { state: { eligibilityApi } } = ConfigStateStatic;

  return eligibilityApi;
};

const getReviewsApi = () => {
  const { state: { reviewsApi } } = ConfigStateStatic;

  return reviewsApi;
};

const getProviderVersions = () => {
  const { state: { providersVersions } } = ConfigStateStatic;

  return providersVersions;
};

const getDefaultHeaders = () => {
  const providersVersions = getProviderVersions();

  return {
    ...defaultHeaders,
    ...(providersVersions ? { [PROVIDERS_VERSIONS_KEY]: providersVersions } : {}),
  };
};

const getUser = () => {
  const { state } = UserStateStatic || {};

  return Number.isInteger(state?.id) ? state : {};
};

const getNonCognitoAuthToken = () => {
  const token = getUser().auth_token;

  return (token)
    ? { 'x-access-token': token }
    : {};
};

const getAuthToken = () => {
  const { state } = ConfigStateStatic || {};
  const { state: userState } = UserStateStatic || {};

  if (state?.featureFlags?.showCognitoLogin
    && userState?.cognitoTokens) {
    return getCognitoAuthToken();
  }

  return getNonCognitoAuthToken();
};

export const tryJSONParse = (data) => {
  try {
    const json = JSON.parse(data);
    return { data: json, success: true };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    return { data, success: false };
  }
};

export const processResponse = async (res, expectedErrorCodes) => {
  const { state } = ConfigStateStatic || {};
  const { state: userState } = UserStateStatic || {};
  const isInvalidCredentialsResponse = !res.ok && res.status === 403;
  const response = await res.text() || null;

  const { data, success } = tryJSONParse(response);
  if (state?.featureFlags?.showCognitoLogin && userState?.cognitoTokens) {
    if (isInvalidCredentialsResponse) {
      window?.Sentry?.captureException?.(new Error('User encountered 403, we should trigger logout'), {
        level: 'info',
      });
    }
  }

  if (!res.ok && !expectedErrorCodes?.includes?.(res.status)) {
    throw new Error(success ? (
      data?.message
      || data?.code
      || data?.statusMessage
      || data?.statusCode
      || JSON.stringify(data)
    ) : data);
  }

  return data;
};

// GET
export const webappGet = async (slug = '', query = {}) => timeoutFetch(`${slug}?${qs.stringify(query)}`, {
  headers: { ...getDefaultHeaders() },
  method: 'GET',
}).then(processResponse);

export const webappGetWithOrigin = async (slug = '', query = {}) => timeoutFetch(`${window.location.origin}${slug}?${qs.stringify(query)}`, {
  headers: { ...getDefaultHeaders() },
  method: 'GET',
}).then(processResponse);

// eslint-disable-next-line default-param-last
export const gatewayGet = async (
  slug = '',
  query = {},
  ms = DEFAULT_ABORT_TIME,
  { abortController, ...opts } = {},
  headers = {},
  loggerLevel = undefined,
) => timeoutFetch(`${getGatewayApi()}${slug}?${qs.stringify(query)}`, {
  abortController,
  headers: {
    ...getAuthToken(),
    ...getDefaultHeaders(),
    ...(headers || {}),
  },
  loggerLevel,
  method: 'GET',
  ms,
  ...opts,
}).then(processResponse);

export const gatewayGetProduction = async (slug = '', query = {}) => timeoutFetch(`https://api.motorway.co.uk${slug}?${qs.stringify(query)}`, {
  headers: {
    ...getDefaultHeaders(),
  },
  method: 'GET',
}).then(processResponse);

export const eligibilityGet = async (slug = '', query = {}) => timeoutFetch(`${getEligibilityApi()}${slug}?${qs.stringify(query)}`, {
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
  },
  method: 'GET',
}).then(processResponse);

export const reviewsGet = async (slug = '', query = {}) => timeoutFetch(`${getReviewsApi()}${slug}?${qs.stringify(query)}`, {
  headers: { ...getDefaultHeaders() },
  method: 'GET',
}).then(processResponse);

export const getRecentSalesProduction = async () => timeoutFetch('https://recent-sales.motorway.co.uk', {
  method: 'GET',
}).then(processResponse);

export const getWordpressProduction = async (slug = '') => timeoutFetch(`https://motorway.co.uk/api/wpengine${slug}`, {
  method: 'GET',
}).then(processResponse);

export const getWordpressStage = async (slug = '', headers = null) => timeoutFetch(`https://stage.motorway.co.uk/api/wpengine${slug}`, {
  headers: {
    ...headers,
    ...getDefaultHeaders(),
  },
  method: 'GET',
}).then(processResponse);

// POST
export const webappPost = async (slug = '', payload = {}) => fetch(slug, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
  },
  method: 'POST',
}).then(processResponse);

// PIR-1157 we are switching to reference absolute endpoints so we can mock API with MSW
// Temp duplicating of webappPost (with origin) so we can be certain it works before switching the other requests.
export const webappPostWithOrigin = async (slug = '', payload = {}) => fetch(`${window.location.origin}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
    'x-sp-session-id': getSnowplowIds()?.snowplowSessionId ?? '',
  },
  method: 'POST',
}).then(processResponse);

export const gatewayPost = async (slug = '', payload = {}, headers = {}, abortController = {}) => fetch(`${getGatewayApi()}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
    ...(headers || {}),
  },
  method: 'POST',
  signal: abortController.signal,
}).then(processResponse);

export const gatewayPut = async (slug = '', payload = {}, headers = {}) => fetch(`${getGatewayApi()}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
    ...(headers || {}),
  },
  method: 'PUT',
}).then(processResponse);

export const confirmationOfPayeePost = async (slug = '', payload = {}) => fetch(`${getGatewayApi()}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
  },
  method: 'POST',
}).then((res) => processResponse(res, [400]));

export const gatewayGetWithStatusCB = async (
  slug = '',
  query = {},
  updateStatusCB = () => { },
  { abortController } = { },
  headers = {},
  ms = DEFAULT_ABORT_TIME,
  isAuthProtected = false,
) => timeoutFetch(`${getGatewayApi()}${slug}?${qs.stringify(query)}`, {
  abortController,
  headers: {
    ...(isAuthProtected ? getAuthToken() : {}),
    ...getDefaultHeaders(),
    ...headers,
  },
  method: 'GET',
  ms,
}).then((res) => {
  updateStatusCB(res.status);
  return processResponse(res);
});

export const gatewayPostWithXMwayUser = async (slug = '', payload = {}, headers = {}, abortController = {}) => gatewayPost(
  slug,
  payload,
  {
    ...headers,
    'x-mway-user': getAuthToken()['x-access-token'],
  },
  abortController,
);

export const gatewayPostWithStatusCB = async (
  slug = '',
  payload = {},
  headers = {},
  updateStatusCB = () => { },
  { abortController } = { },
) => timeoutFetch(`${getGatewayApi()}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
    ...(headers || {}),
  },
  method: 'POST',
  signal: abortController?.signal,
}).then((res) => {
  updateStatusCB(res.status);
  return processResponse(res);
});

// DELETE

export const gatewayDelete = async (slug = '', payload = {}) => fetch(`${getGatewayApi()}${slug}`, {
  body: JSON.stringify({ ...payload }),
  headers: {
    'Content-Type': 'application/json',
    ...getAuthToken(),
    ...getDefaultHeaders(),
  },
  method: 'DELETE',
}).then((res) => processResponse(res));
