import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { classNamePropType } from 'Utilities/propTypes';

import styles from './Steps.scss';

const Steps = ({ className, config }) => (
  <div className={cx(styles.steps, className)}>
    {config.map((item) => {
      const {
        height,
        imgPng,
        imgWebp,
        text,
        time,
        width,
      } = item;

      return (
        <div key={time} className={styles.stepsCard}>
          <picture>
            { imgWebp && <source srcSet={imgWebp} type="image/webp" /> }
            <img
              alt={time}
              loading="lazy"
              src={imgPng}
              {...{ height, width }}
            />
          </picture>
          <h3>{time}</h3>
          <p>{text}</p>
        </div>
      );
    })}
  </div>
);

Steps.propTypes = {
  className: classNamePropType,
  config: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Steps;
