import React from 'react';
import cx from 'classnames';

import { Button } from '@motorway/mw-highway-code';

import { Modal } from 'Storybook/components/local/Modal/Modal';

import styles from './ConfirmModal.scss';

type ConfirmModalProps = {
  children: React.ReactNode;
  className?: string;
  confirmText?: string | React.ReactNode;
  modalClassName?: string;
  onConfirm: () => void;
  onReject?: () => void;
  rejectText?: string;
  shouldCloseOnEsc?: boolean;
  shouldCloseOnOverlayClick?: boolean;
};

const ConfirmModal = ({
  children,
  className,
  confirmText = '',
  modalClassName,
  onConfirm,
  onReject,
  rejectText,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
}: ConfirmModalProps) => (
  <Modal
    centered={false}
    className={cx(modalClassName)}
    dismissModal={onReject}
    shouldCloseOnEsc={shouldCloseOnEsc}
    shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    size="small"
  >
    <div className={cx(styles.component, className)}>
      <div>
        {children}
      </div>
      <div className={styles.actions}>
        <Button fullWidth label={confirmText || 'Continue'} onClick={onConfirm} />
        {onReject && (
          <Button fullWidth
            label={rejectText || 'Cancel'}
            onClick={onReject}
            variant="secondary"
          />
        )}
      </div>
    </div>
  </Modal>
);

export default ConfirmModal;
