import { SELL_MY_CAR_PATH } from '../wordpress';

import { GAEvent, PAGES, snowplowEvent } from './analytics';
import { GENERIC_ANALYTICS_EVENTS } from './analyticsGenericEvents';
import { getAdditionalParams } from './analyticsValuations';

export const HOMEPAGE_SECTIONS = {
  CLAIM: 'claim',
  FAQ: 'faq',
  FULL_BLEED_IMAGE: 'full_bleed_image',
  GUIDES: 'guides',
  HERO: 'hero',
  HOW_IT_WORKS: 'how_it_works',
  NEWS_AND_TOOLS: 'whats_new_tile',
  RECOMMENDED_BY: 'recommended_by',
  RELATED_GUIDES: 'related_guides',
  REVIEWS: 'reviews',
  SELLER_STORIES: 'seller_stories',
  SOCIAL_PROOF: 'social_proof',
} as const;

export const LANDINGPAGE_SECTIONS = {
  HERO: 'Landing Page Hero',
} as const;

export type SectionName =
  | (typeof HOMEPAGE_SECTIONS)[keyof typeof HOMEPAGE_SECTIONS]
  | (typeof LANDINGPAGE_SECTIONS)[keyof typeof LANDINGPAGE_SECTIONS];

export const EVENT_ACTIONS = {
  CTA_CLICKED: 'CTA Clicked',
  GUIDE_CLICKED: 'Guide clicked',
  GUIDES_CAROUSEL_SCROLLED: 'Guides carousel scrolled',
  GUIDES_CTA_CLICKED: 'Explore guides CTA clicked',
  HERO_MILEAGE_CTA_CLICKED: 'Continue CTA clicked',
  HERO_VRM_CTA_CLICKED: 'Value my car CTA clicked',
  HERO_VRM_INPUT_FOCUSSED: 'VRM input focussed',
  NEWS_AND_TOOLS_TILE_CLICKED: 'Tile clicked',
  SECTION_LOADED: 'Section Loaded',
} as const;

export const HOMEPAGE_GA_TAGS = {
  GUIDE_CLICKED: (data: {
    readonly link: string;
    readonly title: string;
  }) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.GUIDE_CLICKED,
      eventCategory: HOMEPAGE_SECTIONS.GUIDES,
      eventLabel: data.title,
    });
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: PAGES.HOME,
      label: data.title,
      name: 'guides_tile',
      url: data.link,
    });
  },
  GUIDES_EXPLORE_CLICKED: (url: string) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.GUIDES_CTA_CLICKED,
      eventCategory: HOMEPAGE_SECTIONS.GUIDES,
    });
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: PAGES.HOME,
      name: 'guides_explore',
      url,
    });
  },
  GUIDES_SCROLLED: (guideTitle: string) => GAEvent({
    eventAction: EVENT_ACTIONS.GUIDES_CAROUSEL_SCROLLED,
    eventCategory: HOMEPAGE_SECTIONS.GUIDES,
    eventLabel: guideTitle,
  }),

  HERO_INVALID_VRM_WARNING_SHOWN: (vrm: string) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.HERO_VRM_CTA_CLICKED,
      eventCategory: HOMEPAGE_SECTIONS.HERO,
      eventLabel: vrm ? 'Invalid VRM warning displayed' : 'No VRM',
    });
    snowplowEvent({
      data: { vrm },
      schema: 'smv_upperfunnel_vrm_submit_error',
    });
  },

  HERO_MILEAGE_CTA_CLICKED: (isHomepage = false) => GAEvent({
    eventAction: EVENT_ACTIONS.HERO_MILEAGE_CTA_CLICKED,
    eventCategory: isHomepage ? HOMEPAGE_SECTIONS.HERO : LANDINGPAGE_SECTIONS.HERO,
    eventLabel: 'Mileage input submitted',
  }),

  HERO_MILEAGE_INPUT_EMPTY: (isHomepage = false) => GAEvent({
    eventAction: EVENT_ACTIONS.HERO_MILEAGE_CTA_CLICKED,
    eventCategory: isHomepage ? HOMEPAGE_SECTIONS.HERO : LANDINGPAGE_SECTIONS.HERO,
    eventLabel: 'Mileage input empty',
  }),

  HERO_MILEAGE_SHOWN: (vrm: string, isHomepage = false) => {
    GAEvent({
      eventAction: 'Mileage input is in view',
      eventCategory: isHomepage ? HOMEPAGE_SECTIONS.HERO : LANDINGPAGE_SECTIONS.HERO,
    });
    snowplowEvent({
      ...getAdditionalParams({ vrm }),
      schema: 'smv_upperfunnel_mileage_arrival',
    });
  },

  HERO_VRM_CTA_CLICKED: () => {
    GAEvent({
      eventAction: EVENT_ACTIONS.HERO_VRM_CTA_CLICKED,
      eventCategory: HOMEPAGE_SECTIONS.HERO,
      eventLabel: 'VRM added',
    });
  },

  HERO_VRM_INPUT_FOCUSSED: () => {
    GAEvent({
      eventAction: EVENT_ACTIONS.HERO_VRM_INPUT_FOCUSSED,
      eventCategory: HOMEPAGE_SECTIONS.HERO,
    });
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({ category: PAGES.HOME, name: 'vrm_input' });
  },

  NEWS_AND_TOOLS_LOADED: (label?: string) => GAEvent({
    eventAction: EVENT_ACTIONS.SECTION_LOADED,
    eventCategory: HOMEPAGE_SECTIONS.NEWS_AND_TOOLS,
    eventLabel: label,
  }),

  NEWS_AND_TOOLS_TILE_CLICKED: (label: string, url: string) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.NEWS_AND_TOOLS_TILE_CLICKED,
      eventCategory: HOMEPAGE_SECTIONS.NEWS_AND_TOOLS,
      eventLabel: label,
    });
    GENERIC_ANALYTICS_EVENTS.CLICK_EVENT({
      category: PAGES.HOME,
      label,
      name: HOMEPAGE_SECTIONS.NEWS_AND_TOOLS,
      url,
    });
  },

  SECTION_CTA_CLICKED: (category: SectionName, label?: string, redirectTo?: string) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.CTA_CLICKED,
      eventCategory: category,
    });
    GENERIC_ANALYTICS_EVENTS.CTA_CLICK(
      {
        button_label: label ?? 'Sell Your Car',
        category: PAGES.HOME,
        label: category,
        name: 'sell_my_car',
        url: redirectTo ?? `https://motorway.co.uk${SELL_MY_CAR_PATH}`,
      },
    );
  },

  SECTION_LOADED: (category: SectionName, label?: string) => {
    GAEvent({
      eventAction: EVENT_ACTIONS.SECTION_LOADED,
      eventCategory: category,
      eventLabel: label,
    });
    GENERIC_ANALYTICS_EVENTS.SECTION_LOAD_EVENT({
      category: PAGES.HOME,
      label,
      name: category,
    });
  },

} as const;
