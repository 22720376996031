import React, { useContext } from 'react';
import { withRouter } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { UserContext } from 'Context/user';
import { VehicleLookupContext } from 'Context/vehicleLookup';

import { GA_TAGS } from 'Utilities/analytics';
import { SP_FOOTER_EVENTS } from 'Utilities/analytics/analyticsFooter';
import { usePageTransitionState } from 'Utilities/animation';
import { vehicleType } from 'Utilities/helpers';
import { useFeatureFlags } from 'Utilities/hooks';
import routing from 'Utilities/routing';

import VrmCheckerFooter from '../../../components/vrm/VrmCheckerFooter/VrmCheckerFooter';
import { routes } from '../../../routes';
import Texts from '../../../texts.json';
import { usePageRefEvents } from '../common/Hooks/usePageRefEvents';
import { CUSTOMER_HUB_VALUATION_ROUTE } from '../customerHub/CustomerHubHelpers';

import FooterLinks from './Components/FooterLinks/FooterLinks';
import FooterReviews from './Components/FooterReviews/FooterReviews';

import styles from './Footer.scss';

const T = new MDText(Texts);

const Footer = ({ history, location }) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);
  const { userState: user } = useContext(UserContext);
  const { showCognitoLogin } = useFeatureFlags();

  const onVehicleLookupLoad = () => SP_FOOTER_EVENTS.FOOTER_VRM_CHECKER_LOADED({ label: location.pathname });
  const { setRef } = usePageRefEvents({ onRefTrigger: onVehicleLookupLoad });

  const { animate: transitView } = usePageTransitionState();
  const animate = history?.location?.state?.animateFooter;

  const {
    hideFooter,
    showDisclaimer,
    showDisclaimerDetail,
    showTrackerDisclaimer,
    useUlezVrmChecker = false, ...route
  } = routing.getMatch(location.pathname, routes);

  // hide footer for cognito journey
  const isCognitoJourneyCheck = showCognitoLogin && (
    (location.pathname === '/sign-in')
    || (route.vrmPath && !Number.isInteger(user?.id))
  );

  if (
    hideFooter
    || (location.pathname === `${CUSTOMER_HUB_VALUATION_ROUTE}/${route?.params?.vrm}` && location.search.includes('redirect'))
    || isCognitoJourneyCheck
  ) {
    return null;
  }

  const showVehicleLookup = !route.hideVehicleLookup;

  let showReviewsCarousel = !route.hideReviews;

  // hide review on valuation page
  if (route.vrmPath && vehicle?.enquiry?.id) {
    showReviewsCarousel = false;
  }

  const displayText = {
    get shouldShowLookupButtonChevron() {
      return true;
    },

    get vehicleLookupButtonText() {
      return T.translate('footerView.vehicleLookup.buttonLabel');
    },

    get vehicleLookupTitle() {
      if (route?.params?.brand || location.pathname === '/' || route?.params?.partner) {
        return T.translate('footerView.vehicleLookup.homeTitle');
      }

      return T.translate('footerView.vehicleLookup.title', {
        suffix: (<span className={styles.noWrap}>{T.translate('footerView.vehicleLookup.titleSuffix')}</span>),
        vehicleType: vehicleType(location.pathname),
      });
    },
  };

  return (
    <footer
      className={cx({
        [styles.hidden]: transitView,
        [styles.animate]: animate,
      })}
    >
      {showReviewsCarousel
        ? <FooterReviews />
        : null}
      {showVehicleLookup
        ? (
          <Content
            ref={(ref) => setRef(ref)}
            center
            className={styles.vehicleLookup}
            element="section"
            wrapper={{
              className: styles.vehicleLookupWrapper,
            }}
          >
            <div className={styles.vehicleLookupInner}>
              {!useUlezVrmChecker && (
                <h4>
                  <span data-nosnippet data-cy="footerVrmTitle">
                    {displayText.vehicleLookupTitle}
                  </span>
                </h4>
              )}
              <VrmCheckerFooter
                {...{ useUlezVrmChecker }}
                analyticsEvents={{
                  onInputFocus: GA_TAGS.FOOTER_VRM_INPUT_FOCUSED,
                  vrmSubmittedSuccessfully: () => SP_FOOTER_EVENTS.FOOTER_VRM_CHECKER_SUBMITTED({
                    buttonLabel: displayText.vehicleLookupButtonText,
                    label: location.pathname,
                  }),
                }}
                buttonProps={{
                  className: ' mw-button-large',
                  label: displayText.vehicleLookupButtonText,
                }}
                chevron={displayText.shouldShowLookupButtonChevron}
                e2eName="footer vrm checker"
                id="footer-vrm"
              />
            </div>
          </Content>
        )
        : null}
      <FooterLinks element="section" {...{ showDisclaimer, showDisclaimerDetail, showTrackerDisclaimer }} />
      <svg
        height="0"
        style={{ position: 'absolute', visibility: 'hidden' }}
        version="1.1"
        width="0"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter id="brand-triangle-rounding">
            <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="5"></feGaussianBlur>
            <feColorMatrix
              in="blur"
              mode="matrix"
              result="goo"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 25 -9"
            ></feColorMatrix>
            <feComposite in="SourceGraphic" in2="goo" operator="atop"></feComposite>
          </filter>
        </defs>
      </svg>
    </footer>
  );
};

Footer.propTypes = {
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
};

export default withRouter(Footer);
