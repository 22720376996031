import React, { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import cx from 'classnames';

import styles from './Header.scss';

type Props = {
  children: React.ReactNode;
  menuOpen: boolean;
  setMenuOpen: (isOpen:boolean) => void;
};

type EventType = {
  target: HTMLElement;
}

type ShiftEffectType = (s: string) => void;

type MenuActionType = 'add' | 'remove';

const MENU_ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
};

let shiftEffect:ShiftEffectType;

export const HeaderLinksMobile = ({
  children,
  menuOpen,
  setMenuOpen,
}: Props) => {
  const { pathname } = useLocation();
  const sidebar = useRef<HTMLDivElement | null>(null);

  const listenForCloseEvent = useCallback((event: EventType) => {
    if (!sidebar?.current?.contains(event.target)) {
      setMenuOpen(false);
      shiftEffect(MENU_ACTION.REMOVE);
    }
  }, [sidebar, setMenuOpen]);

  shiftEffect = useCallback((vector:string) => {
    const { body, documentElement } = document;

    documentElement.classList[vector as MenuActionType]('mw-shift-left');

    // https://github.com/facebook/react/issues/24657
    setTimeout(() => (body as any)[`${vector}EventListener`]('click', listenForCloseEvent), 1);
  }, [listenForCloseEvent]);

  useEffect(() => () => {
    setMenuOpen(false);
    shiftEffect(MENU_ACTION.REMOVE);
  }, [setMenuOpen]);

  useEffect(() => {
    const { current: el } = sidebar;

    if (menuOpen && el) {
      window.scrollTo(0, 0);
      el.scrollTop = 0;
    }

    shiftEffect(menuOpen ? MENU_ACTION.ADD : MENU_ACTION.REMOVE);
  }, [sidebar, menuOpen]);

  useEffect(() => {
    setMenuOpen(false);
  }, [pathname, setMenuOpen]);

  return (
    <nav
      ref={sidebar}
      className={cx(styles.nav, styles.links, styles.navMobile)}
      role='navigation'
    >
      {children}
    </nav>
  );
};
