import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer } from 'react';
import fetch from 'cross-fetch';

import { GA_VARIABLES, SNOWPLOW_USER_CUSTOMER_CONTEXT } from 'Utilities/analytics';
import { isEmptyObject } from 'Utilities/helpers';

import { User } from '../utilities/user/@types';

type UserState = User | null;

type UserActionPayloadMap = {
  readonly update:Partial<UserState>;
}

type UserActions = {
  [K in keyof UserActionPayloadMap]: (data: UserActionPayloadMap[K]) => void;
}

type UserContext = {
  readonly userActions:UserActions;
  readonly userState:UserState;
}

type UserAuthTokens = {
  user:Partial<UserState>;
}

type UserContextReducer = (
  state: UserState,
  action: {
    [K in keyof UserActionPayloadMap]: {
      readonly payload: UserActionPayloadMap[K];
      readonly type: K;
    };
  }[keyof UserActionPayloadMap],
) => UserState;

type UserProviderProps = {
  readonly children:ReactNode;
  readonly value:UserState;
}

// Set any hardcoded values
const initialState = null;

const initialActions:UserActions = {
  update: () => {},
};

// Based off https://dev.to/burhanuday/react-context-api-usereducer-redux-ogo
const UserContext = createContext<UserContext>({ userActions: initialActions, userState: initialState });

// Export state available outside of React
const UserStateStatic = {
  state: {},
};

// Export dispatch available outside of React
const UserDispatch: { actions: Partial<UserActions> } = {
  actions: {},
};

const userAuthTokens:UserAuthTokens = {
  user: {}, // Essentially treating this as useRef to pass auth tokens to API endpoints
};

const actionKeys:{[K in keyof UserActionPayloadMap]:K} = {
  update: 'update',
};

const isUser = <T extends UserState | Partial<UserState>>(
  state: T,
): state is Extract<T, User> => Number.isInteger(state?.id);

const updateState = (current:UserState, data:Partial<UserState>):UserState => {
  if (isUser(current)) {
    return { ...current, ...data };
  }
  return { ...current ?? {}, ...data } as UserState;
};

const reducer: UserContextReducer = (state, { payload, type }) => {
  const { vehicles, ...user } = { ...payload };

  switch (type as string) {
  case actionKeys.update: {
    const updatedState = updateState(state, user);

    userAuthTokens.user = updatedState;

    return updatedState;
  }
    // no default
  }

  userAuthTokens.user = state;

  return state;
};

const UserProvider = ({ children, value }:UserProviderProps) => {
  const user:Partial<UserState> = { ...(initialState ?? {}), ...value };

  const [userState, dispatch] = useReducer(
    reducer,
    isUser(user) ? user : null,
  );

  useEffect(() => {
    if (isUser(userState)) {
      window?.Sentry?.setUser?.({
        email: undefined,
        id: userState.id,
      });

      GA_VARIABLES.userId = userState.id;
      GA_VARIABLES.phone_number = userState.phone;
      GA_VARIABLES.postcode = `${userState.postcode}`;

      SNOWPLOW_USER_CUSTOMER_CONTEXT(userState);
    }
  }, [userState]);

  const userActions: UserActions = {
    update: useCallback(
      (fields) => {
        if (fields) {
          dispatch({ payload: fields, type: actionKeys.update });
        }
      },
      [dispatch],
    ),
  };

  // Expose dispatch and state outside React,
  UserDispatch.actions = Object.freeze(userActions);
  UserStateStatic.state = Object.freeze({
    ...userState,
  });
  userAuthTokens.user = userState;

  return (
    <UserContext.Provider
      value={{
        userActions,
        userState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const withUserContext = <T extends Record<string, unknown>>(Component:(props:T)=>React.JSX.Element) => (
  // @ts-ignore
  React.forwardRef((props:T, ref) => (
    <UserContext.Consumer>
      {(context) => <Component ref={ref} userContext={context} {...props} />}
    </UserContext.Consumer>
  ))
);

export {
  UserContext,
  UserDispatch,
  UserProvider,
  UserStateStatic,
};

export const getCognitoAuthToken = ():Record<string, string> => {
  const { auth_token: authToken, cognitoTokens } = userAuthTokens.user ?? {};

  const tokens:Record<string, string> = {};

  if (cognitoTokens?.accessToken && cognitoTokens?.idToken) {
    tokens.authorization = cognitoTokens.accessToken;
    tokens['id-token'] = cognitoTokens.idToken;
  } else if (authToken) {
    tokens['x-access-token'] = authToken;
  }

  return Object.freeze(tokens);
};

const setClientSession = async (
  tokens:User['cognitoTokens'],
): Promise<Partial<{ error: string; ok: boolean; status: number }>> =>
  fetch(`${window.location.origin}/api/cognito-login`, {
    body: JSON.stringify({ ...tokens }),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  })
    .then(({ ok, status }) => ({ ok, status }))
    .catch((error) => {
      window?.Sentry?.captureException?.(new Error(error));
      return { error };
    });

export const establishCognitoSession = async (
  cognitoTokens: User['cognitoTokens'],
) => {
  if (!cognitoTokens || isEmptyObject(cognitoTokens || {})) {
    return { error: new Error('No Cognito tokens'), success: false };
  }
  const { error, ok } = await setClientSession(cognitoTokens);
  if (ok && UserDispatch.actions.update) {
    UserDispatch.actions.update?.({ cognitoTokens });
  }
  return { error, success: ok };
};

export const useUserPartial = ():Partial<User> => {
  const { userState } = useContext(UserContext);
  return { ...userState };
};
